import {
  Action,
  BlockButton,
  Container,
  TableWrapper,
  Title,
  UnBlockButton,
  UserImage,
  UserNameWrapper,
} from "styles/dashboard";
import {
  ActionsWrapper,
  Filter,
  FilterWrapper,
  Icon,
  Reset,
  TableTitle,
  Text,
  Wrapper,
} from "styles/pages/userManagement";
import { getToast } from "utils";
import { Table } from "antd";
import { DataType, TodayJobData } from "constants/tableData";
import { useLocation, useNavigate } from "react-router-dom";
import viewIcone from "assets/images/view.png";
import uploadIcone from "assets/images/upload.png";
import deleteIcone from "assets/images/deleteIcon.png";

import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { ColumnsType } from "antd/es/table";
import filterIcone from "assets/images/filterIcon.png";
import driverImage2 from "assets/images/driverImage.png";
import { FilterValue } from "interfaces/iCommon";
import UserIcon from "../../../assets/images/no-user.jpg";
import {
  DATE_FILTERS,
  PAGINATION_SIZE,
  TOAST_MESSAGE,
  filterInitialValue,
} from "constants/common";
import { useDispatch } from "react-redux";
import { postApi } from "api/api.client";
import { APIS, APP_BASE_URL, BASE_URL } from "api/api.constant";

import {
  DeleteButton,
  DownloadButton,
  DownloadText,
} from "styles/jobManagement";
import { useEffect, useState } from "react";
import { CommonFilter } from "components";
import { disabledFutureDate } from "components/dateInput";
import {
  calculateTotalPickupCount,
  capitalizeFirstLetter,
  shortenLocation,
} from "components/commonFunction";

export const PastJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page");
  const [filter, setfilter] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeFilterTab, setActiveFilterTab] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGINATION_SIZE);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filterValue, setFilterValue] =
    useState<FilterValue>(filterInitialValue);

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.JOB_PASTJOB, { ...filterValue, page: page, size: pageSize })
      .then((res) => {
        let response: any = res;
        setTableData(response?.data?.jobInfo);
        setTotalCount(response?.data?.pagination?.totalCount);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [triggerFilter, page, pageSize, refresh]);

  useEffect(() => {
    const handleSelect = () => {
      queryParams.set("page", String(page));
      const newUrl = `${location.pathname}?${queryParams}`;
      window.history.replaceState(null, "", newUrl);
    };
    handleSelect();
  }, [page]);

  const handleSingleDownload = async (id: any) => {
    try {
      dispatch(toggleLoader({ loader: true }));
      postApi(APIS.JOB_DOWNLOAD_INDIVIDUAL, { jobId: id })
        .then(async (res) => {
          let response: any = res;
          const fileUrl = `${BASE_URL}${response?.data?.url}`;
          const link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute("download", response?.data?.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          getToast("success", "Downloaded Successfully");
        })
        .catch((err) => {})
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const Pastcolumns: ColumnsType<DataType> = [
    {
      title: "Name",
      key: "name",
      render: (_, record: any) => (
        <UserNameWrapper>
          <UserImage
            src={
              record?.createdBy?.photo !== null
                ? record?.createdBy?.photo
                : UserIcon
            }
          />
          {capitalizeFirstLetter(
            record?.createdBy?.full_name ? record?.createdBy?.full_name : ""
          )}
        </UserNameWrapper>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "First Location",
      dataIndex: "FirstLocation",
      key: "First Location",
      render: (_, record: any) => {
        const firstLocation =
          record?.locations[0]?.location ||
          record?.locations[0]?.addressLine1 +
            " " +
            record?.locations[0]?.addressLine2;
        return <div>{firstLocation}</div>;
      },
    },
    {
      title: "Last Location",
      dataIndex: "LastLocation",
      key: "last Location",

      render: (_, record: any) => {
        const lastLocation =
          record?.jobDropOff[record?.jobDropOff?.length - 1]
            ?.drop_off_location ||
          record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine1 +
            " " +
            record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine2;
        return <div>{lastLocation}</div>;
      },
    },
    {
      title: <Action>Total Stops</Action>,
      dataIndex: "totalStops",
      key: "Total Stops",
      render: (_, record) => {
        return <Action>{record?.totalStops}</Action>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record) => {
        return (
          <div>
            {Number(record?.amount || 0)?.toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            })}
          </div>
        );
      },
    },
    {
      title: <Action>Status</Action>,
      key: "status",
      render: (_, record) => {
        return (
          <>
            {record?.status === "Cancelled" ? (
              <BlockButton
                style={{ backgroundColor: "#FFD5D2", color: "#F34235" }}
              >
                Cancelled
              </BlockButton>
            ) : (
              <UnBlockButton
                style={{ backgroundColor: "#C2FFCF", color: "#01851E" }}
              >
                Completed
              </UnBlockButton>
            )}
          </>
        );
      },
    },

    {
      title: <Action>Actions</Action>,
      key: "Action",
      render: (_, record) => {
        return (
          <ActionsWrapper>
            <Icon
              src={viewIcone}
              onClick={() =>
                record?.status === "Cancelled"
                  ? navigate(
                      `/job-management/job-past/cancelled/${record?._id}`
                    )
                  : navigate(
                      `/job-management/job-past/completed/${record?._id}`
                    )
              }
            />
            <Icon
              src={uploadIcone}
              onClick={() => handleSingleDownload(record?._id)}
            />
          </ActionsWrapper>
        );
      },
    },
  ];

  const handleOk = () => {
    setfilter(false);
  };

  const handleCancel = () => {
    setfilter(false);
  };

  const onSearchClick = () => {
    if (filterValue.date_from !== null && filterValue.date_to !== null) {
      setTriggerFilter(!triggerFilter);
      setfilter(!filter);
      setPage(1);
    }
  };

  const handleDelete = async () => {
    try {
      dispatch(toggleLoader({ loader: true }));
      postApi(APIS.JOB_DELETE, { ...filterValue, page: page, size: pageSize })
        .then((res) => {
          getToast("success", TOAST_MESSAGE.DELETED);
          setRefresh(!refresh);
        })
        .catch((err) => {})
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    } catch (error) {
      console.error("API Error:", error);
    } finally {
    }
  };

  const handleDownload = async () => {
    try {
      dispatch(toggleLoader({ loader: true }));
      postApi(APIS.JOB_DOWNLOAD, { ...filterValue, page: page, size: pageSize })
        .then(async (res) => {
          let response: any = res;
          if (response?.data === "No Data Found") {
            getToast("error", "No Data Found");
            return;
          }
          const fileUrl = `${BASE_URL}${response?.data?.url}`;
          const link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute("download", response?.data?.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          getToast("success", "Downloaded Successfully");
        })
        .catch((err) => {
          console.log("err==", err.message);
        })
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const onResetClick = () => {
    setFilterValue(filterInitialValue);
    setTriggerFilter(!triggerFilter);
  };

  return (
    <Container>
      <Wrapper>
        <Title>Past Jobs List</Title>
        <TableWrapper>
          <FilterWrapper className="customeFilter">
            {filterValue?.date_from && (
              <Reset onClick={onResetClick}>
                <Text>Reset</Text>
              </Reset>
            )}
            <Filter onClick={() => setfilter(!filter)}>
              <Text>Filter</Text>
              <Icon src={filterIcone} />
            </Filter>

            <DownloadButton onClick={handleDownload}>
              <DownloadText>Download all</DownloadText>
              <Icon src={uploadIcone} />
            </DownloadButton>
            <DeleteButton onClick={handleDelete}>
              <Icon src={deleteIcone} />
              <DownloadText>Delete</DownloadText>
            </DeleteButton>
          </FilterWrapper>
          <Table
            className="tableStyle"
            columns={Pastcolumns}
            dataSource={tableData}
            pagination={{
              total: totalCount,
              onChange: (page: any, pageSize: any) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </TableWrapper>
        {filter && (
          <CommonFilter
            filter={filter}
            handleOk={handleOk}
            handleCancel={handleCancel}
            DATE_FILTERS={DATE_FILTERS}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            onSearchClick={onSearchClick}
            disabledDate={disabledFutureDate}
            job_type="past_job"
            isDisabled={filterValue.date_from === "" ? true : false}
          />
        )}
      </Wrapper>
    </Container>
  );
};
