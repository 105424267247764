import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  TrackWrapper,
  TractLine,
  Line,
  Place,
  IconWrap,
  LocationText,
  LineWrap,
  Dot,
  StateLine,
  Location,
  TrackConainer,
  DataWrapper,
} from "styles/commonStyle";
import { BreadcrumbWrap, Container, TableTitle, Title } from "styles/dashboard";
import { Icon, Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import { LocationTable } from "components/locationTable";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import truck from "assets/images/truck.png";
import truck2 from "assets/images/truck2.png";
import { DriverComponent } from "components/driverDetails";
import { NameText, NameWrap, NoteWrap } from "styles/jobManagement";
import { Breadcrumb } from "antd";
import { useDispatch } from "react-redux";
import { UserDetail } from "constants/tableData";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { postApi } from "api/api.client";
import { APIS, color, jobDropDetails, locationDetails } from "api/api.constant";
import moment from "moment";
import { JobNote } from "components/jobNote";
import {
  calculateTotalPickupCount,
  formatDropLocationText,
  formatLocationText,
  getLocation,
  getdropLocation,
} from "components/commonFunction";
import { Signature } from "components/signature";

const UserTodayJobs = () => {
  const { userId, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.GET_JOB_DETAILS}`, { jobId: id, userId: userId })
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  const locationsData = data?.locations;
  const jobDropOffData = data?.jobDropOff;

  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Date", data: moment(data?.dates).format("ll") },
    { label: "Items", data: calculateTotalPickupCount(locationsData) },
    { label: "Amount", data: `£${data?.amount}` },
    {
      label: "Weight",
      data: data?.weight + " Kg" || 0,
    },
    {
      key: "time",
      label: "Time",
      data: data?.time_slot !== "ASAP" ? data?.start_time : data?.time_slot,
    },
  ];

  return (
    <Container>
      <BreadcrumbWrap>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: <Link to={"/"}>Home</Link>,
            },
            {
              title: <Link to={"/user-management"}>User Management</Link>,
            },
            {
              title: (
                <Link to={`/user-management/${userId}`}>User Job Detail</Link>
              ),
            },
            {
              title: "Today's Job Details",
            },
          ]}
        />
      </BreadcrumbWrap>
      <Wrapper>
        <TopSection>
          <Title>Today's Job Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <UserWrapper>
          <NameWrap>
            <NameText>Driver Detail</NameText>
          </NameWrap>
          <DriverComponent data={data} />
          <VahicleWrapper>
            {vehicleData.map(({ label, data }) => (
              <TextWrapper>
                <Label>
                  <div>{label}</div>
                </Label>
                <DataWrapper>{data}</DataWrapper>
              </TextWrapper>
            ))}
          </VahicleWrapper>
          {data?.note?.length > 0 && <JobNote type="Note" data={data?.note} />}
          {data?.document?.length > 0 && <Documents data={data?.document} />}
          {locationsData?.length > 0 && (
            <LocationTable
              locationsData={locationsData}
              jobDropOffData={jobDropOffData}
            />
          )}
          <TrackWrapper>
            <TableTitle className="doc">Location Detail</TableTitle>

            {locationsData?.map((item: any, index: any) => {
              return (
                <>
                  <TrackConainer key={index}>
                    <TractLine>
                      <Place>
                        {index === 0
                          ? "Job Started"
                          : getLocation(locationsData[index - 1])}
                      </Place>
                      <Line
                        color={
                          item?.en_route
                            ? color?.colorReach
                            : color?.colorUnComplete
                        }
                      />
                      <IconWrap>
                        <img
                          src={item?.en_route ? truck : truck2}
                          alt="truck_img"
                          width={50}
                          height={item?.en_route ? 50 : 30}
                        />
                      </IconWrap>
                      <Place>{getLocation(item)}</Place>
                    </TractLine>
                    {locationDetails.map((locationDetail, index) => {
                      const isEnRoute =
                        locationDetail.text === "On Route" && item?.en_route;
                      const isReached =
                        locationDetail.text === "Reached at location" &&
                        item?.reached_at_location;
                      const isCollected =
                        locationDetail.text === "Collected" && item?.collected;

                      const textColor =
                        isEnRoute || isReached || isCollected
                          ? color?.colorComplete
                          : item?.en_route
                          ? color?.colorReach
                          : color?.colorUnComplete;

                      return (
                        <>
                          <Location key={index}>
                            <LocationText color={textColor}>
                              {locationDetail.text}
                            </LocationText>
                            <LineWrap>
                              <Dot color={textColor}></Dot>
                              <StateLine color={textColor}></StateLine>
                            </LineWrap>
                            <LocationText
                              color={textColor}
                              className="smallText"
                            >
                              {locationDetail.description === ""
                                ? getLocation(item)
                                : locationDetail.description}
                            </LocationText>
                            {/* <span className="smallText">
                              {(locationDetail.text === "On Route" &&
                                item?.en_route &&
                                `At ${item?.startTime}`) ||
                                (locationDetail.text ===
                                  "Reached at location" &&
                                  item?.reached_at_location &&
                                  `At ${item?.reachTime}`) ||
                                (locationDetail.text === "Collected" &&
                                  item?.collected &&
                                  `At ${item?.collectedTime}`)}
                            </span> */}
                            <span className="locationText">
                              {/* {(locationDetail.text === "On Route" &&
                                formatLocationText(
                                  "On Route",
                                  item,
                                  item?.startTime
                                )) ||
                                formatLocationText(
                                  "Reached at location",
                                  item,
                                  item?.reachTime
                                ) ||
                                formatLocationText(
                                  "Collected",
                                  item,
                                  item?.collectedTime
                                )} */}
                              {formatLocationText(locationDetail, item)}
                            </span>
                          </Location>
                        </>
                      );
                    })}
                  </TrackConainer>
                </>
              );
            })}
            {jobDropOffData?.map((item: any, index: any) => {
              return (
                <>
                  <TrackConainer key={index}>
                    <TractLine>
                      <Place>
                        {index === 0
                          ? getLocation(
                              locationsData[locationsData?.length - 1]
                            )
                          : getdropLocation(jobDropOffData[index - 1])}
                      </Place>
                      <Line
                        color={
                          item?.en_route
                            ? color?.colorReach
                            : color?.colorUnComplete
                        }
                      />
                      <IconWrap>
                        <img
                          src={item?.en_route ? truck : truck2}
                          alt="truck_img"
                          width={50}
                          height={item?.en_route ? 50 : 30}
                        />
                      </IconWrap>
                      <Place>{getdropLocation(item)}</Place>
                    </TractLine>
                    {jobDropDetails.map((locationDetail, index) => {
                      const isEnRoute =
                        locationDetail.text === "On Route" && item?.en_route;
                      const isReached =
                        locationDetail.text === "Reached at location" &&
                        item?.reached_at_location;

                      const isDelivered =
                        locationDetail.text === "Delivered at Location" &&
                        item?.is_delivered;

                      const textColor =
                        isEnRoute || isReached || isDelivered
                          ? color?.colorComplete
                          : item?.en_route
                          ? color?.colorReach
                          : color?.colorUnComplete;

                      return (
                        <>
                          <Location key={index}>
                            <LocationText color={textColor}>
                              {locationDetail.text}
                            </LocationText>
                            <LineWrap>
                              <Dot color={textColor}></Dot>
                              <StateLine color={textColor}></StateLine>
                            </LineWrap>
                            <LocationText
                              color={textColor}
                              className="smallText"
                            >
                              {locationDetail.description === ""
                                ? getdropLocation(item)
                                : locationDetail.description}
                            </LocationText>
                            <span className="locationText">
                              {/* {formatDropLocationText(
                                "On Route",
                                item,
                                item?.startTime
                              ) ||
                                formatDropLocationText(
                                  "Reached at location",
                                  item,
                                  item?.reachTime
                                ) ||
                                formatDropLocationText(
                                  "Delivered at Location",
                                  item,
                                  item?.delivered_time
                                )} */}
                              {/* {(locationDetail.text === "On Route" &&
                                item?.en_route &&
                                `At ${item?.startTime}`) ||
                                (locationDetail.text ===
                                  "Reached at location" &&
                                  item?.reached_at_location &&
                                  `At ${item?.reachTime}`) ||
                                (locationDetail.text ===
                                  "Delivered at Location" &&
                                  item?.is_delivered &&
                                  `At ${item?.delivered_time}`)} */}

                              {formatDropLocationText(locationDetail, item)}
                            </span>
                          </Location>
                        </>
                      );
                    })}
                  </TrackConainer>
                </>
              );
            })}
          </TrackWrapper>

          {jobDropOffData?.length > 0 && <Signature data={jobDropOffData} />}
        </UserWrapper>
      </Wrapper>
    </Container>
  );
};

export default UserTodayJobs;
