import { postApi } from "api/api.client";
import { APIS } from "api/api.constant";
import Navbar from "components/navbar";
import { CSM_SLUG_TYPE } from "constants/common";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Wrapper, Title, Title2, Content } from "styles/view";

export const MobilePrivacyData = () => {
  const [data, setData] = useState("");
  // useEffect(() => {
  //   postApi(APIS.GET_CMS, {
  //     type: CSM_SLUG_TYPE.PRIVACY_POLICY,
  //   })
  //     .then((res) => {
  //       let response: any = res;
  //       setData(response?.data?.content);
  //     })
  //     .catch((err) => {})
  //     .finally(() => {});
  // }, []);

  return (
    <Container>
      <Wrapper>
        {/* <Title>Privacy Policy</Title>
        <Content dangerouslySetInnerHTML={{ __html: data }}></Content> */}
        <h1>Privacy and Data Protection Policy for Kindra Cargo</h1>
        <p>Last Updated: 30/07/2024</p>
        <h2>Table of Contents</h2>
        <ol>
          <li>Introduction</li>
          <li>Identity and Contact Details of the Data Controller</li>
          <li>Contact Details for Data Protection Inquiries</li>
          <li>
            Categories of Personal Data Collected and Purposes of Data
            Processing
          </li>
          <li>Legal Basis for Processing</li>
          <li>Recipients or Categories of Recipients of the Personal Data</li>
          <li>Details of Data Transfers Outside the UK</li>
          <li>Data Retention Periods</li>
          <li>Data Subject Rights</li>
          <li>Right to Withdraw Consent</li>
          <li>Right to Lodge a Complaint with the ICO</li>
          <li>Source of Personal Data</li>
          <li>Automated Decision-Making and Profiling</li>
          <li>Cookies and Tracking Technologies</li>
          <li>Changes to This Policy</li>
          <li>Age Verification and Children's Privacy</li>
          <li>Data Security Measures</li>
          <li>Data Breach Notification</li>
          <li>Updating Your Personal Data</li>
          <li>Special Category Data</li>
          <li>Third-Party Services or Links</li>
          <li>Glossary of Terms</li>
        </ol>

        <h2>Introduction</h2>
        <p>
          Welcome to Kindra Cargo ("we", "our", "us"). We are committed to
          protecting and respecting your privacy. This Privacy and Data
          Protection Policy outlines how we collect, use, and protect your
          personal data when you use our courier app for drivers to accept bulk
          loads from customers.
        </p>

        <h2>1. Identity and Contact Details of the Data Controller</h2>
        <p>
          Kindra Cargo LTD
          <br />
          181 Balfour Street, OL41NS
          <br />
          Email: admin@kindracargo.co.uk
          <br />
          Phone: +44 7441395840
        </p>

        <h2>2. Contact Details for Data Protection Inquiries</h2>
        <p>
          We do not currently have a designated Data Protection Officer. For any
          questions about this policy or our data protection practices, please
          contact us using the details provided in Section 1.
        </p>

        <h2>
          3. Categories of Personal Data Collected and Purposes of Data
          Processing
        </h2>
        <p>
          We collect and process the following types of personal data for the
          specified purposes:
        </p>
        <ul>
          <li>
            <strong>Identification data</strong> (e.g., name, address, email,
            phone number):
            <ul>
              <li>To create and manage your account</li>
              <li>To communicate with you about our services</li>
              <li>To verify your identity for security purposes</li>
            </ul>
          </li>
          <li>
            <strong>Location data</strong> (e.g., GPS data):
            <ul>
              <li>To match drivers with nearby customers</li>
              <li>To track deliveries and provide estimated arrival times</li>
            </ul>
          </li>
          <li>
            <strong>Vehicle information</strong> (e.g., make, model,
            registration number):
            <ul>
              <li>To verify driver eligibility</li>
              <li>
                To ensure appropriate vehicles are matched with specific loads
              </li>
            </ul>
          </li>
          <li>
            <strong>Transaction data</strong> (e.g., payment information, order
            details):
            <ul>
              <li>To process payments</li>
              <li>To maintain records of services provided</li>
            </ul>
          </li>
          <li>
            <strong>Device data</strong> (e.g., IP address, browser type):
            <ul>
              <li>To improve our app's functionality</li>
              <li>To detect and prevent fraud</li>
            </ul>
          </li>
        </ul>
        <p>
          Example: When you sign up as a driver, we collect your name, email,
          and phone number to create your account and communicate with you about
          available loads.
        </p>

        <h2>4. Legal Basis for Processing</h2>
        <p>
          We process your personal data based on the following legal grounds:
        </p>
        <ul>
          <li>
            <strong>Contractual Necessity</strong>:
            <ul>
              <li>
                Processing identification data, location data, and transaction
                data to provide our courier services
              </li>
            </ul>
          </li>
          <li>
            <strong>Legal Obligation</strong>:
            <ul>
              <li>
                Retaining transaction data for tax and accounting purposes
              </li>
            </ul>
          </li>
          <li>
            <strong>Legitimate Interests</strong>:
            <ul>
              <li>
                Using device data to improve our services and prevent fraud
              </li>
              <li>
                Processing vehicle information to ensure appropriate matching of
                drivers and loads
              </li>
            </ul>
          </li>
          <li>
            <strong>Consent</strong>:
            <ul>
              <li>
                Sending marketing communications (which you can opt out of at
                any time)
              </li>
            </ul>
          </li>
        </ul>
        <p>
          We have carefully considered and balanced our legitimate interests
          against your individual rights and interests.
        </p>

        <h2>5. Recipients or Categories of Recipients of the Personal Data</h2>
        <p>We may share your personal data with:</p>
        <ul>
          <li>Payment processors to handle transactions</li>
          <li>Cloud storage providers to securely store your data</li>
          <li>Analytics providers to help us improve our services</li>
          <li>Regulatory and law enforcement authorities if required by law</li>
        </ul>
        <p>
          We ensure that all third-party service providers are contractually
          bound to protect your personal data in compliance with UK GDPR.
        </p>

        <h2>6. Details of Data Transfers Outside the UK</h2>
        <p>
          Currently, we do not transfer your personal data outside the UK. If
          this changes in the future, we will update this policy and ensure that
          any such transfers are protected by appropriate safeguards, such as:
        </p>
        <ul>
          <li>
            Transferring to countries deemed adequate by the UK government
          </li>
          <li>Implementing standard contractual clauses approved by the ICO</li>
          <li>Obtaining your explicit consent for the proposed transfer</li>
        </ul>

        <h2>7. Data Retention Periods</h2>
        <p>
          We retain personal data for as long as necessary to fulfil the
          purposes outlined in this policy unless a longer retention period is
          required or permitted by law. Specific retention periods include:
        </p>
        <ul>
          <li>
            <strong>Account data</strong>: Retained for the duration of your
            account, and for 6 months after account closure to handle any
            post-closure queries or disputes
          </li>
          <li>
            <strong>Transaction data</strong>: Retained for 5 years for tax and
            legal compliance
          </li>
          <li>
            <strong>Location data</strong>: Retained for 30 days to handle any
            delivery disputes
          </li>
          <li>
            <strong>Device data</strong>: Retained for 12 months to support
            fraud detection and prevention
          </li>
        </ul>

        <h2>8. Data Subject Rights</h2>
        <p>
          Under UK GDPR, you have the following rights regarding your personal
          data:
        </p>
        <ul>
          <li>
            <strong>Right to access</strong>: Request access to your personal
            data within 30 days of your request
          </li>
          <li>
            <strong>Right to rectification</strong>: Request correction of
            inaccurate or incomplete data within 30 days
          </li>
          <li>
            <strong>Right to erasure</strong>: Request deletion of your personal
            data under certain conditions, within 30 days
          </li>
          <li>
            <strong>Right to restrict processing</strong>: Request restriction
            of processing under certain conditions
          </li>
          <li>
            <strong>Right to data portability</strong>: Request transfer of your
            data to another service in a machine-readable format
          </li>
          <li>
            <strong>Right to object</strong>: Object to processing based on
            legitimate interests or for direct marketing
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us using the details provided
          in Section 1. We will respond to your request within 30 days.
        </p>
        <p>
          Example: If you want to access all the personal data we hold about
          you, you can email us at admin@kindracargo.co.uk with your request,
          and we'll provide the information within 30 days.
        </p>

        <h2>9. Right to Withdraw Consent</h2>
        <p>
          Where we process data based on consent, you have the right to withdraw
          this consent at any time by contacting us using the details in Section
          1. This will not affect the lawfulness of processing based on consent
          before its withdrawal. Please note that withdrawing consent for
          essential communications may affect our ability to provide you with
          our services.
        </p>

        <h2>10. Right to Lodge a Complaint with the ICO</h2>
        <p>
          If you believe your data protection rights have been violated, you
          have the right to lodge a complaint with the Information
          Commissioner's Office (ICO):
        </p>
        <ul>
          <li>
            Website: <a href="https://www.ico.org.uk">www.ico.org.uk</a>
          </li>
          <li>Phone: 0303 123 1113</li>
        </ul>

        <h2>11. Source of Personal Data</h2>
        <p>
          We primarily collect data directly from you when you use our app. In
          some cases, we may receive data from third parties such as:
        </p>
        <ul>
          <li>Credit reference agencies for identity verification</li>
          <li>Insurance providers for driver eligibility checks</li>
        </ul>
        <p>
          We will inform you if we collect personal data about you from sources
          other than direct interaction.
        </p>

        <h2>12. Automated Decision-Making and Profiling</h2>
        <p>
          We use automated processes to match drivers with customer loads based
          on location, vehicle type, and availability. This automated
          decision-making is necessary for the performance of our contract with
          you. While this process doesn't produce legal effects, it does
          determine the delivery opportunities presented to you. You can request
          human intervention, express your point of view, or contest any
          automated decisions by contacting us using the details in Section 1.
        </p>
        <p>
          Example: Our system might automatically match you with a nearby bulk
          load delivery based on your location and vehicle type. If you believe
          you've been unfairly excluded from a delivery opportunity, you can
          contact us to review the decision.
        </p>

        <h2>13. Cookies and Tracking Technologies</h2>
        <p>
          Our app uses the following types of cookies and similar technologies:
        </p>
        <ul>
          <li>
            <strong>Strictly necessary cookies</strong>: To enable core app
            functionality
          </li>
          <li>
            <strong>Performance cookies</strong>: To analyse app usage and
            improve our services
          </li>
          <li>
            <strong>Functionality cookies</strong>: To remember your preferences
          </li>
        </ul>
        <p>
          You can manage your cookie preferences through your device settings or
          by contacting us.
        </p>

        <h2>14. Changes to This Policy</h2>
        <p>
          We may update this policy from time to time. We will notify you of any
          significant changes via email and in-app notification at least 30 days
          before the changes take effect. We encourage you to review this policy
          periodically.
        </p>

        <h2>15. Age Verification and Children's Privacy</h2>
        <p>
          Our app is not intended for use by individuals under the age of 18. We
          implement age verification checks during the account creation process.
          If we become aware that we have collected personal data from a minor
          without parental consent, we will take steps to delete such
          information promptly.
        </p>

        <h2>16. Data Security Measures</h2>
        <p>
          We implement robust security measures to protect your data, including:
        </p>
        <ul>
          <li>Encryption of data in transit using TLS 1.3 protocol</li>
          <li>Encryption of data at rest using AES-256 encryption</li>
          <li>
            Regular security audits and penetration testing by third-party
            specialists
          </li>
          <li>Multi-factor authentication for user accounts</li>
          <li>
            Strict access controls and authentication procedures for our staff
          </li>
        </ul>

        <h2>17. Data Breach Notification</h2>
        <p>
          In the event of a data breach that is likely to result in a high risk
          to your rights and freedoms, we will notify you and the ICO without
          undue delay, within 72 hours of becoming aware of the breach. This
          notification will include the nature of the breach, likely
          consequences, and measures taken to address the breach.
        </p>

        <h2>18. Updating Your Personal Data</h2>
        <p>
          You can update your personal information at any time by logging into
          your account or by contacting us using the details in Section 1. We
          encourage you to promptly update your information if it changes.
        </p>

        <h2>19. Special Category Data</h2>
        <p>
          We do not intentionally collect or process special category data (such
          as health information, biometric data, or information about racial or
          ethnic origin). If we need to process such data in the future, we will
          only do so with your explicit consent or where permitted by law, and
          we will update this policy accordingly.
        </p>

        <h2>20. Third-Party Services or Links</h2>
        <p>
          Our app may contain links to third-party websites or services. We are
          not responsible for the privacy practices of these third parties. We
          encourage you to review their privacy policies before providing any
          personal information.
        </p>

        <h2>21. Glossary of Terms</h2>
        <ul>
          <li>
            <strong>Personal Data</strong>: Any information relating to an
            identified or identifiable natural person.
          </li>
          <li>
            <strong>Data Controller</strong>: The entity that determines the
            purposes and means of processing personal data.
          </li>
          <li>
            <strong>Data Processing</strong>: Any operation performed on
            personal data, such as collection, recording, organization,
            structuring, storage, adaptation or alteration, retrieval,
            consultation, use, disclosure by transmission, dissemination or
            otherwise making available, alignment or combination, restriction,
            erasure or destruction.
          </li>
          <li>
            <strong>UK GDPR</strong>: The United Kingdom General Data Protection
            Regulation, the data protection law that applies in the UK.
          </li>
          <li>
            <strong>ICO</strong>: Information Commissioner's Office, the UK's
            independent authority set up to uphold information rights in the
            public interest.
          </li>
          <li>
            <strong>Cookies</strong>: Small text files that are placed on your
            device to help the website provide a better user experience.
          </li>
          <li>
            <strong>Special Category Data</strong>: Personal data revealing
            racial or ethnic origin, political opinions, religious or
            philosophical beliefs, trade union membership, genetic data,
            biometric data, data concerning health or data concerning a natural
            person's sex life or sexual orientation.
          </li>
        </ul>

        <p>
          This Privacy and Data Protection Policy ensures that your personal
          data is handled in compliance with the UK GDPR, providing transparency
          and protection for our users. For any questions or concerns, please
          contact us using the details provided in Section 1.
        </p>
      </Wrapper>
    </Container>
  );
};
