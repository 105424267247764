import { BreadcrumbWrap, Container, Title } from "styles/dashboard";
import {
  Details,
  DetailsWrapper,
  ImageWrapper,
  InnerWrapper,
  Image,
  UserWrapper,
  UserImage,
  TextWrapper,
  Text1,
  Text2,
  TractLine,
  DetailCard,
  Head,
  Table,
  TableRow,
  TableCell,
  TopSection,
  BackButton,
  ButtonText,
  UpDateButton,
  ButtonWrapper,
} from "styles/unAssignedJob";
import fram1 from "assets/images/fram1.png";
import userImage from "assets/images/userImage.png";
import { IoIosArrowBack } from "react-icons/io";
import { Icon, Wrapper } from "styles/pages/userManagement";
import truckicon from "assets/images/truck.png";
import { IconWrap, Line, Place } from "styles/commonStyle";
import { Documents } from "components/documents";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Breadcrumb } from "antd";
import moment from "moment";
import { APP_BASE_URL } from "api/api.constant";
import { LocationTable } from "components/locationTable";
import { JobNote } from "components/jobNote";
import UserIcon from "../../../../../assets/images/no-user.jpg";
import {
  calculateTotalPickupCount,
  capitalizeFirstLetter,
  shortenLocation,
} from "components/commonFunction";
export const DriverUnAssignedJobDetails = () => {
  const { driverId, jobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const userdetail = location.state?.data || {};
  const locationdetail = location.state?.record || {};

  const details = [
    { key: "vehicle", label: "Vehicle", data: locationdetail?.vehicle },
    { key: "phone", label: "Phone Number", data: userdetail?.phone },
    {
      key: "amount",
      label: "Price",
      data: Number(locationdetail?.amount || 0)?.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      }),
    },
    {
      key: "dates",
      label: "Date",
      data: moment(locationdetail?.dates, "YYYY-MM-DD").format("ll"),
    },
    {
      key: "totalStops",
      label: "Total Stops",
      data: locationdetail?.totalStops,
    },
    {
      key: "totalItems",
      label: "Total Items",
      data: calculateTotalPickupCount(
        locationdetail?.locations > 0 ? locationdetail?.locations : []
      ),
    },
    {
      key: "time",
      label: "Time",
      data:
        locationdetail?.time_slot !== "ASAP"
          ? `${locationdetail?.start_time}`
          : `${locationdetail?.time_slot}`,
    },
  ];

  return (
    <Container>
      <BreadcrumbWrap>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: <Link to={"/"}>Home</Link>,
            },
            {
              title: (
                <Link to={"/driver-management/List"}>
                  Driver Management List
                </Link>
              ),
            },
            {
              title: (
                <Link to={`/driver-management/${driverId}`}>
                  Driver Details
                </Link>
              ),
            },
            {
              title: (
                <Link
                  to={`/driver-management/${driverId}/unassignedJob/${jobId}`}
                >
                  Unassigned Job List
                </Link>
              ),
            },
            {
              title: "Unassigned Job Detail",
            },
          ]}
        />
      </BreadcrumbWrap>
      <Wrapper>
        <TopSection>
          <Title>Un Assigned Job Detail</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <InnerWrapper>
          <DetailsWrapper>
            <ImageWrapper>
              <Image src={fram1} />
            </ImageWrapper>
            <Details>
              <UserWrapper>
                <UserImage
                  src={
                    userdetail?.photo !== null ? userdetail?.photo : UserIcon
                  }
                />
                <TextWrapper>
                  <Text1>
                    {capitalizeFirstLetter(
                      userdetail?.full_name ? userdetail?.full_name : ""
                    )}
                  </Text1>
                  <Text2>Searching near by driver</Text2>
                </TextWrapper>
              </UserWrapper>
              <TractLine>
                <Place>
                  {locationdetail?.locations?.length > 0
                    ? locationdetail?.locations[0]?.location
                    : locationdetail?.locations[0]?.addressLine1 +
                      " " +
                      locationdetail?.locations[0]?.addressLine2}
                </Place>
                <Line color="#F7931E" className="customeStyle" />
                <IconWrap>
                  <Icon src={truckicon} className="truck" />
                </IconWrap>
                <Place>
                  {locationdetail?.jobDropOff?.length > 0
                    ? locationdetail?.jobDropOff[
                        locationdetail?.jobDropOff?.length - 1
                      ]?.drop_off_location
                    : locationdetail?.jobDropOff[
                        locationdetail?.jobDropOff?.length - 1
                      ]?.addressLine1 +
                      " " +
                      locationdetail?.jobDropOff[
                        locationdetail?.jobDropOff?.length - 1
                      ]?.addressLine2}
                </Place>
              </TractLine>
              <DetailCard>
                <Head>Details</Head>
                <Table>
                  {details.map(({ key, label, data }) => (
                    <TableRow key={key}>
                      <TableCell>{label}</TableCell>
                      <TableCell>{data}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </DetailCard>
            </Details>
          </DetailsWrapper>
          {locationdetail?.note?.length > 0 && (
            <JobNote type="Note" data={locationdetail?.note} />
          )}
          {locationdetail?.document?.length > 0 && (
            <Documents data={locationdetail?.document} />
          )}
          {locationdetail?.locations?.length > 0 && (
            <LocationTable
              locationsData={locationdetail?.locations}
              jobDropOffData={locationdetail?.jobDropOff}
            />
          )}
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
};
