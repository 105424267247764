import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  TrackWrapper,
  TractLine,
  Line,
  Place,
  IconWrap,
  LocationText,
  LineWrap,
  Dot,
  StateLine,
  Location,
  TrackConainer,
  SignWrapper,
  SignatureImageWrapper,
  SignatureImage,
  DataWrapper,
} from "styles/commonStyle";
import { Container, TableTitle, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  RatingAndReviewTilte,
  RatingTilte,
  SignatureTilte,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NameWrap, NameText, InnerContainer } from "styles/jobManagement";
import { DriverComponent } from "components/driverDetails";
import customerImage from "assets/images/customerImage.png";
import { FaTruckFast } from "react-icons/fa6";
import {
  CommentWrapper,
  RateAndSignWrap,
  RateWrapper,
  Rating,
  RatingCommentWrapper,
  RatingWrap,
  ReviewWrapper,
} from "styles/driverCompletedJob";
import { Rate } from "antd";
import truck from "assets/images/truck.png";
import { CompeteWrap, Text } from "styles/completedJob";
import { FaCheckCircle } from "react-icons/fa";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";
import { useDispatch } from "react-redux";
import { UserDetail } from "constants/tableData";
import {
  APIS,
  APP_BASE_URL,
  color,
  jobDropDetails,
  locationDetails,
} from "api/api.constant";
import { postApi } from "api/api.client";
import moment from "moment";
import { JobNote } from "components/jobNote";
import { LocationTable } from "components/locationTable";
import {
  calculateTotalPickupCount,
  formatDropLocationText,
  formatLocationText,
  getLocation,
  getdropLocation,
} from "components/commonFunction";
import { Signature } from "components/signature";
const CompleteJobdetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.JOB_DETAILS}`, { jobId: id })
      .then((res) => {
        let response: any = res;
        console.log("response57--", response);
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  // let color = {
  //   colorComplete: "#01851e",
  //   colorReach: "#F7931E",
  //   colorUnComplete: "#606161",
  // };

  const locationsData = data?.locations;
  const jobDropOffData = data?.jobDropOff;

  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Date", data: moment(data?.dates).format("ll") },
    { label: "Items", data: calculateTotalPickupCount(locationsData) },
    {
      label: "Amount",
      data: data?.additional_amount
        ? ` £${Number(data?.amount || 0)} + £${
            data?.additional_amount || 0
          } = ${(
            Number(data?.amount || 0) + Number(data?.additional_amount || 0)
          )?.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          })}  `
        : `${Number(data?.amount || 0)?.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          })}`,
    },

    {
      key: "time",
      label: "Time",
      data: data?.time_slot !== "ASAP" ? data?.start_time : data?.time_slot,
    },
  ];

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Title>Completed Job Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <InnerContainer>
          <UserWrapper>
            <NameWrap>
              <NameText>Driver Detail</NameText>
            </NameWrap>
            <DriverComponent data={data?.driverId} />
          </UserWrapper>

          <UserWrapper>
            <NameWrap>
              <NameText>User Details</NameText>
            </NameWrap>
            <UserComponent data={data?.createdBy} />

            <TableTitle>Vehicle Detail</TableTitle>
            <VahicleWrapper>
              {vehicleData.map(({ label, data }) => (
                <TextWrapper>
                  <Label>
                    <div>{label}</div>
                  </Label>
                  <DataWrapper>{data}</DataWrapper>
                </TextWrapper>
              ))}
            </VahicleWrapper>
            {data?.note?.length > 0 && (
              <JobNote type="Note" data={data?.note} />
            )}
            {data?.document?.length > 0 && <Documents data={data?.document} />}
            {/* {data?.locations?.length > 0 && (
              <LocationTable data={data?.locations} />
            )} */}
            {locationsData?.length > 0 && (
              <LocationTable
                locationsData={locationsData}
                jobDropOffData={jobDropOffData}
              />
            )}

            <TrackWrapper>
              <RatingWrap>
                <TableTitle className="doc">Job Detail</TableTitle>
              </RatingWrap>

              {locationsData?.map((item: any, index: any) => {
                return (
                  <>
                    <TrackConainer key={index}>
                      <TractLine>
                        <Place>
                          {index === 0
                            ? "Job Started"
                            : getLocation(locationsData[index - 1])}
                        </Place>
                        <Line
                          color={
                            item?.en_route
                              ? color?.colorReach
                              : color?.colorUnComplete
                          }
                        />
                        <IconWrap>
                          <img
                            src={truck}
                            alt="truck_img"
                            width={50}
                            height={50}
                          />
                        </IconWrap>
                        <Place>{getLocation(item)}</Place>
                      </TractLine>
                      {locationDetails.map((locationDetail, index) => {
                        return (
                          <>
                            <Location key={index}>
                              <LocationText color={color?.colorComplete}>
                                {locationDetail.text}
                              </LocationText>
                              <LineWrap>
                                <Dot color={color?.colorComplete}></Dot>
                                <StateLine
                                  color={color?.colorComplete}
                                ></StateLine>
                              </LineWrap>
                              <LocationText
                                color={color?.colorUnComplete}
                                className="smallText"
                              >
                                {locationDetail.description === ""
                                  ? getLocation(item)
                                  : locationDetail.description}
                              </LocationText>
                              <span className="locationText">
                                {formatLocationText(locationDetail, item)}
                              </span>
                            </Location>
                          </>
                        );
                      })}
                    </TrackConainer>
                  </>
                );
              })}
              {jobDropOffData?.map((item: any, index: any) => {
                return (
                  <>
                    <TrackConainer key={index}>
                      <TractLine>
                        <Place>
                          {index === 0
                            ? getLocation(
                                locationsData[locationsData?.length - 1]
                              )
                            : getdropLocation(jobDropOffData[index - 1])}
                        </Place>
                        <Line
                          color={
                            item?.en_route
                              ? color?.colorReach
                              : color?.colorUnComplete
                          }
                        />
                        <IconWrap>
                          <img
                            src={truck}
                            alt="truck_img"
                            width={50}
                            height={50}
                          />
                        </IconWrap>
                        <Place>{getdropLocation(item)}</Place>
                      </TractLine>
                      {jobDropDetails.map((locationDetail, index) => {
                        return (
                          <>
                            <Location key={index}>
                              <LocationText color={color?.colorComplete}>
                                {locationDetail.text}
                              </LocationText>
                              <LineWrap>
                                <Dot color={color?.colorComplete}></Dot>
                                <StateLine
                                  color={color?.colorComplete}
                                ></StateLine>
                              </LineWrap>
                              <LocationText
                                color={color?.colorUnComplete}
                                className="smallText"
                              >
                                {locationDetail.description === ""
                                  ? getdropLocation(item)
                                  : locationDetail.description}
                              </LocationText>

                              <span className="locationText">
                                {formatDropLocationText(locationDetail, item)}
                              </span>
                            </Location>
                          </>
                        );
                      })}
                    </TrackConainer>
                  </>
                );
              })}
            </TrackWrapper>
            {/* <RateAndSignWrap>
              <SignWrapper>
                <SignatureTilte className="doc">
                  Customer Signature
                </SignatureTilte>
                <SignatureImageWrapper>
                  <SignatureImage
                    crossOrigin="anonymous"
                    src={
                      data?.signature !== null
                        ? `${APP_BASE_URL}${data?.signature}`
                        : "Signature not found"
                    }
                  />
                </SignatureImageWrapper>
              </SignWrapper>
              {data?.ratingdata && (
                <RateWrapper>
                  <Rating>
                    <RatingTilte className="doc">Rating</RatingTilte>(
                    {data?.ratingdata?.review}):
                    <Rate
                      disabled
                      allowHalf
                      value={data?.ratingdata?.review}
                      style={{ color: "#F7931E" }}
                    />
                  </Rating>
                  <Rating>
                    <CommentWrapper>
                      <RatingTilte className="doc">Review :</RatingTilte>
                      <ReviewWrapper>
                        <RatingCommentWrapper>
                          {data?.ratingdata?.comment}
                        </RatingCommentWrapper>
                      </ReviewWrapper>
                    </CommentWrapper>
                  </Rating>
                </RateWrapper>
              )}
            </RateAndSignWrap> */}
            {data?.note?.length > 0 && (
              <JobNote type="Reason of Extra Amount" data={data?.bill_notes} />
            )}
            {jobDropOffData?.length > 0 && <Signature data={jobDropOffData} />}

            <SignWrapper>
              {data?.ratingdata && (
                <>
                  <RatingAndReviewTilte className="doc">
                    Rating and Review
                  </RatingAndReviewTilte>
                  <RateWrapper>
                    <Rating>
                      <RatingTilte className="doc">Rating</RatingTilte>(
                      {data?.ratingdata?.review}):
                      <Rate
                        disabled
                        allowHalf
                        value={data?.ratingdata?.review}
                        style={{ color: "#F7931E" }}
                      />
                    </Rating>
                    <Rating>
                      <CommentWrapper>
                        <RatingTilte className="doc">
                          Review :
                          <ReviewWrapper>
                            {data?.ratingdata?.comment}
                          </ReviewWrapper>
                        </RatingTilte>
                      </CommentWrapper>
                    </Rating>
                  </RateWrapper>
                </>
              )}
            </SignWrapper>
          </UserWrapper>
          <CompeteWrap>
            <FaCheckCircle size={50} />
            <Text>Completed Job</Text>
          </CompeteWrap>
        </InnerContainer>
      </Wrapper>
    </Container>
  );
};

export default CompleteJobdetail;
