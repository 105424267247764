import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  TrackWrapper,
  TractLine,
  Line,
  Place,
  IconWrap,
  LocationText,
  LineWrap,
  Dot,
  StateLine,
  Location,
  TrackConainer,
  DataWrapper,
} from "styles/commonStyle";
import { Container, TableTitle, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import { CancleReasonWrap, Confirmation, Reason } from "styles/cancelledJob";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import truck2 from "assets/images/truck2.png";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { UserDetail } from "constants/tableData";
import { postApi } from "api/api.client";
import { APIS, jobDropDetails, locationDetails } from "api/api.constant";
import moment from "moment";
import { JobNote } from "components/jobNote";
import { LocationTable } from "components/locationTable";
import {
  calculateTotalPickupCount,
  getLocation,
  getdropLocation,
} from "components/commonFunction";

const CancelJobDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.JOB_DETAILS}`, { jobId: id })
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  const locationsData = data?.locations;
  const jobDropOffData = data?.jobDropOff;
  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Date", data: moment(data?.dates).format("ll") },
    { label: "Items", data: calculateTotalPickupCount(locationsData) },
    {
      label: "Amount",
      data: data?.additional_amount
        ? `£${data?.amount} + £${data?.additional_amount} = ${
            Number(data?.amount) + Number(data?.additional_amount)
          }`
        : `£${data?.amount}`,
    },
    {
      label: "Weight",
      data: data?.weight + " Kg" || 0,
    },
    {
      key: "time",
      label: "Time",
      data: data?.time_slot !== "ASAP" ? data?.start_time : data?.time_slot,
    },
  ];

  // const locationDetails = [
  //   { text: "En Route", description: "" },
  //   { text: "Reached at location", description: "Arrived at Location" },
  //   { text: "Collected", description: "Collected 25 box" },
  // ];
  // const locationsData = data?.locations;

  // const getLocation = (item: any) => {
  //   if (item.location) {
  //     return item.location;
  //   } else {
  //     return `${item?.addressLine1} ${item?.addressLine2} ${item?.city} ${item?.state}`;
  //   }
  // };

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Title>Cancelled Job Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <UserWrapper>
          <CancleReasonWrap>
            <Confirmation>You have been Cancelled</Confirmation>
          </CancleReasonWrap>
          <UserComponent data={data?.createdBy} />

          <TableTitle>Vehicle Detail</TableTitle>
          <VahicleWrapper>
            {vehicleData.map(({ label, data }) => (
              <TextWrapper>
                <Label>
                  <div>{label}</div>
                </Label>
                <DataWrapper>{data}</DataWrapper>
              </TextWrapper>
            ))}
          </VahicleWrapper>

          {data?.note?.length > 0 && <JobNote type="Note" data={data?.note} />}
          {data?.document?.length > 0 && <Documents data={data?.document} />}
          {/* {data?.locations?.length > 0 && (
            <LocationTable data={data?.locations} />
          )} */}
          {locationsData?.length > 0 && (
            <LocationTable
              locationsData={locationsData}
              jobDropOffData={jobDropOffData}
            />
          )}
          <TrackWrapper>
            <TableTitle className="doc">Location Detail</TableTitle>
            {locationsData?.map((item: any, index: any) => {
              return (
                <>
                  <TrackConainer key={index}>
                    <TractLine>
                      <Place>
                        {index === 0
                          ? "Job Started"
                          : getLocation(locationsData[index - 1])}
                      </Place>
                      <Line color="#606161" />
                      <IconWrap>
                        <img
                          src={truck2}
                          alt="truck_img"
                          width={50}
                          height={30}
                        />
                      </IconWrap>
                      <Place>{getLocation(item)}</Place>
                    </TractLine>
                    {locationDetails?.map((locationDetail, index) => (
                      <Location key={index}>
                        <LocationText color="#606161">
                          {locationDetail.text}
                        </LocationText>
                        <LineWrap>
                          <Dot color="#606161"></Dot>
                          <StateLine color="#606161"></StateLine>
                        </LineWrap>
                        <LocationText color="#606161" className="smallText">
                          {locationDetail.description === ""
                            ? getLocation(item)
                            : locationDetail.description}
                        </LocationText>
                      </Location>
                    ))}
                  </TrackConainer>
                </>
              );
            })}
            {jobDropOffData?.map((item: any, index: any) => {
              return (
                <>
                  <TrackConainer key={index}>
                    <TractLine>
                      <Place>
                        {index === 0
                          ? getLocation(
                              locationsData[locationsData?.length - 1]
                            )
                          : getdropLocation(jobDropOffData[index - 1])}
                      </Place>
                      <Line color="#606161" />
                      <IconWrap>
                        <img
                          src={truck2}
                          alt="truck_img"
                          width={50}
                          height={30}
                        />
                      </IconWrap>
                      <Place>{getdropLocation(item)}</Place>
                    </TractLine>
                    {jobDropDetails?.map((locationDetail, index) => (
                      <Location key={index}>
                        <LocationText color="#606161">
                          {locationDetail.text}
                        </LocationText>
                        <LineWrap>
                          <Dot color="#606161"></Dot>
                          <StateLine color="#606161"></StateLine>
                        </LineWrap>
                        <LocationText color="#606161" className="smallText">
                          {locationDetail.description === ""
                            ? getdropLocation(item)
                            : locationDetail.description}
                        </LocationText>
                      </Location>
                    ))}
                  </TrackConainer>
                </>
              );
            })}
          </TrackWrapper>
        </UserWrapper>
      </Wrapper>
    </Container>
  );
};

export default CancelJobDetail;
