import {
  DocsWrap,
  DocumentsWrapper,
  DocumnetsContainer,
  PdfDetail,
  PdfWrapper,
  Size,
  Type,
} from "styles/commonStyle";
import { Icon } from "styles/pages/userManagement";
import pdficon from "assets/images/pdf.png";
import viewIcon from "assets/images/view.png";
import { TableTitle } from "styles/dashboard";
import { APIS, APP_BASE_URL } from "api/api.constant";

interface DocumentsArray {
  data: any;
}

export const Documents: React.FC<DocumentsArray> = ({ data }) => {
  console.log("filedata ", data);
  return (
    <DocumentsWrapper>
      <TableTitle className="doc">Documents</TableTitle>
      <DocumnetsContainer>
        {data?.map(
          (
            item: string,
            index: any // Change String to string
          ) => (
            <DocsWrap key={index}>
              <PdfWrapper>
                <Icon src={pdficon} className="pdf" />
                <PdfDetail>
                  <Type>Pdf</Type>
                  {/* <Size>2.5kb/ 1.3Kb Format : pdf</Size> */}
                </PdfDetail>
              </PdfWrapper>
              <a
                rel="noreferrer"
                download
                target="_blank"
                className="download_btn"
                href={item}
              >
                <Icon src={viewIcon} />
              </a>
            </DocsWrap>
          )
        )}
      </DocumnetsContainer>
    </DocumentsWrapper>
  );
};
