import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  DocsWrap,
  PdfDetail,
  PdfWrapper,
  Size,
  Type,
  DocumentWrapper,
} from "styles/commonStyle";
import { Container, Title } from "styles/dashboard";
import { Icon, Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import pdficon from "assets/images/pdf.png";
import viewIcon from "assets/images/view.png";
import {
  AcceptButton,
  RejectButton,
  ButtonsWrapper,
} from "styles/newDriverDetail";
import { DriverComponent } from "components/driverDetails";
import { postApi } from "api/api.client";
import { APIS, APP_BASE_URL } from "api/api.constant";
import { useDispatch } from "react-redux";
import { SyntheticEvent } from "react";
import seprator from "assets/images/seprator.png";
import { TOAST_MESSAGE } from "constants/common";
import { getToast } from "utils";
import moment from "moment";
import { DriverDocuments } from "components/driverdocument";

interface VehicleDetail {
  data: any;
}

const NewDriverDetail: React.FC<VehicleDetail> = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<VehicleDetail["data"]>({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isVerif, setIsVerif] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.GET_NEW_DETAIL_DRIVER + "/" + id, {})
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [isVerif, refresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAction = (type: "accept" | "reject", event: SyntheticEvent) => {
    dispatch(toggleLoader({ loader: true }));

    let apiEndpoint = type === "accept" ? APIS.VERIFIED : APIS.REJECTED;

    postApi(apiEndpoint, {
      driverId: id,
    })
      .then((res) => {
        if (type === "accept") {
          setIsVerif(true);
          getToast("success", TOAST_MESSAGE.ACCEPTED);
        } else {
          getToast("success", TOAST_MESSAGE.REJECTED);
        }

        setRefresh(!refresh);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  };

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Title>New Driver Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <UserWrapper>
          {/* <UserComponent /> */}
          <DriverComponent data={data} />
          {/* <VahicleWrapper className="customeStyle">
            <TextWrapper>
              <Data>Driving Licence Documents</Data>
              {data?.docurl?.length > 0 &&
                data?.docurl.map((items: any, index: any) => (
                  <>
                    <DocsWrap>
                      <PdfWrapper>
                        <Icon src={pdficon} className="pdf" />
                        <PdfDetail>
                          <Type>Pdf</Type>
                        </PdfDetail>
                      </PdfWrapper>
                      <a
                        rel="noreferrer"
                        download
                        target="_blank"
                        className="download_btn"
                        href={items?.docurl}
                      >
                        <Icon src={viewIcon} />
                      </a>
                    </DocsWrap>
                  </>
                ))}
            </TextWrapper>
          </VahicleWrapper> */}

          {/* <img style={{ width: "100%" }} src={seprator} alt="seprator" />
          {data?.extraDoc?.length > 0 && (
            <DriverDocuments data={data?.extraDoc} />
          )} */}
          <ButtonsWrapper>
            <AcceptButton
              type="button"
              style={{
                backgroundColor: data?.isVerified && "lightgray",
                color: data?.isVerified && "black",
              }}
              onClick={(event) => handleAction("accept", event)}
              disabled={data?.isVerified}
            >
              Accept
            </AcceptButton>
            <RejectButton
              type="button"
              onClick={(event) => handleAction("reject", event)}
            >
              Reject
            </RejectButton>
          </ButtonsWrapper>
        </UserWrapper>
      </Wrapper>
    </Container>
  );
};

export default NewDriverDetail;
