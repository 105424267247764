import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  TrackWrapper,
  TractLine,
  Line,
  Place,
  IconWrap,
  LocationText,
  LineWrap,
  Dot,
  StateLine,
  Location,
  TrackConainer,
  SignatureImageWrapper,
  SignatureImage,
  SignWrapper,
  DataWrapper,
} from "styles/commonStyle";
import { Container, TableTitle, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import { FaTruckFast } from "react-icons/fa6";
import { Confirmation, Reason } from "styles/cancelledJob";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  RatingAndReviewTilte,
  RatingTilte,
  SignatureTilte,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import {
  Check,
  CompeteWrap,
  ExtraText,
  ExtraWrap,
  Info,
  Text,
} from "styles/completedJob";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";
import { useEffect, useState } from "react";
import {
  CommentWrapper,
  RateAndSignWrap,
  RateWrapper,
  Rating,
  RatingCommentWrapper,
  RatingWrap,
  ReviewWrapper,
} from "styles/driverCompletedJob";
import { Rate } from "antd";
import { DriverComponent } from "components/driverDetails";
import truck from "assets/images/truck.png";
import { useDispatch } from "react-redux";
import { UserDetail } from "constants/tableData";
import { postApi } from "api/api.client";
import {
  APIS,
  APP_BASE_URL,
  color,
  jobDropDetails,
  locationDetails,
} from "api/api.constant";
import moment from "moment";
import { LocationTable } from "components/locationTable";
import { JobNote } from "components/jobNote";
import {
  calculateTotalPickupCount,
  formatDropLocationText,
  formatLocationText,
  getLocation,
  getdropLocation,
} from "components/commonFunction";
import { Signature } from "components/signature";
const OutCompletedJob = () => {
  const { driverId, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});
  const location = useLocation();
  const userdetail = location.state?.data || {};

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.GET_JOB_DETAILS}`, { jobId: id, userId: driverId })
      .then((res) => {
        let response: any = res;
        console.log("response57--", response);
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  // let color = {
  //   colorComplete: "#01851e",
  //   colorReach: "#F7931E",
  //   colorUnComplete: "#606161",
  // };

  const locationsData = data?.locations;
  const jobDropOffData = data?.jobDropOff;

  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Date", data: moment(data?.dates).format("ll") },
    { label: "Items", data: calculateTotalPickupCount(locationsData) },
    {
      label: "Amount",
      data: data?.additional_amount
        ? ` £${Number(data?.amount || 0)} + £${
            data?.additional_amount || 0
          } = ${(
            Number(data?.amount || 0) + Number(data?.additional_amount || 0)
          )?.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          })}  `
        : `${Number(data?.amount || 0)?.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          })}`,
    },
    {
      key: "time",
      label: "Time",
      data: data?.time_slot !== "ASAP" ? data?.start_time : data?.time_slot,
    },
  ];

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Title>Completed Job Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <UserWrapper>
          <DriverComponent data={userdetail?.driverId} />

          <VahicleWrapper>
            {vehicleData.map(({ label, data }) => (
              <TextWrapper>
                <Label>
                  <div>{label}</div>
                </Label>
                <DataWrapper>{data}</DataWrapper>
              </TextWrapper>
            ))}
          </VahicleWrapper>
          {data?.note?.length > 0 && <JobNote type="Note" data={data?.note} />}
          {data?.document?.length > 0 && <Documents data={data?.document} />}
          {/* {locationsData?.length > 0 && <LocationTable data={locationsData} />} */}
          {locationsData?.length > 0 && (
            <LocationTable
              locationsData={locationsData}
              jobDropOffData={jobDropOffData}
            />
          )}
          <TrackWrapper>
            <RatingWrap>
              <TableTitle className="doc">Location Detail</TableTitle>
            </RatingWrap>
            {locationsData?.map((item: any, index: any) => {
              return (
                <>
                  <TrackConainer key={index}>
                    <TractLine>
                      <Place>
                        {console.log("items>>>", item?.en_route)}
                        {index === 0
                          ? "Job Started"
                          : getLocation(locationsData[index - 1])}
                      </Place>
                      <Line
                        color={
                          item?.en_route
                            ? color?.colorReach
                            : color?.colorUnComplete
                        }
                      />
                      <IconWrap>
                        <img
                          src={truck}
                          alt="truck_img"
                          width={50}
                          height={50}
                        />
                      </IconWrap>
                      <Place>{getLocation(item)}</Place>
                    </TractLine>
                    {locationDetails.map((locationDetail, index) => {
                      return (
                        <>
                          <Location key={index}>
                            <LocationText color={color?.colorComplete}>
                              {locationDetail.text}
                            </LocationText>
                            <LineWrap>
                              <Dot color={color?.colorComplete}></Dot>
                              <StateLine
                                color={color?.colorComplete}
                              ></StateLine>
                            </LineWrap>
                            <LocationText
                              color={color?.colorUnComplete}
                              className="smallText"
                            >
                              {locationDetail.description === ""
                                ? getLocation(item)
                                : locationDetail.description}
                            </LocationText>
                            <span className="locationText">
                              {formatLocationText(locationDetail, item)}
                            </span>
                          </Location>
                        </>
                      );
                    })}
                  </TrackConainer>
                </>
              );
            })}
            {jobDropOffData?.map((item: any, index: any) => {
              return (
                <>
                  <TrackConainer key={index}>
                    <TractLine>
                      <Place>
                        {index === 0
                          ? getLocation(
                              locationsData[locationsData?.length - 1]
                            )
                          : getdropLocation(jobDropOffData[index - 1])}
                      </Place>
                      <Line
                        color={
                          item?.en_route
                            ? color?.colorReach
                            : color?.colorUnComplete
                        }
                      />
                      <IconWrap>
                        <img
                          src={truck}
                          alt="truck_img"
                          width={50}
                          height={50}
                        />
                      </IconWrap>
                      <Place>{getdropLocation(item)}</Place>
                    </TractLine>
                    {jobDropDetails.map((locationDetail, index) => {
                      return (
                        <>
                          <Location key={index}>
                            <LocationText color={color?.colorComplete}>
                              {locationDetail.text}
                            </LocationText>
                            <LineWrap>
                              <Dot color={color?.colorComplete}></Dot>
                              <StateLine
                                color={color?.colorComplete}
                              ></StateLine>
                            </LineWrap>
                            <LocationText
                              color={color?.colorUnComplete}
                              className="smallText"
                            >
                              {locationDetail.description === ""
                                ? getdropLocation(item)
                                : locationDetail.description}
                            </LocationText>
                            <span className="locationText">
                              {formatDropLocationText(locationDetail, item)}
                            </span>
                          </Location>
                        </>
                      );
                    })}
                  </TrackConainer>
                </>
              );
            })}
          </TrackWrapper>

          {data?.note?.length > 0 && (
            <JobNote type="Reason of Extra Amount" data={data?.bill_notes} />
          )}
          {jobDropOffData?.length > 0 && <Signature data={jobDropOffData} />}

          <SignWrapper>
            {data?.ratingdata && (
              <>
                <RatingAndReviewTilte className="doc">
                  Rating and Review
                </RatingAndReviewTilte>
                <RateWrapper>
                  <Rating>
                    <RatingTilte className="doc">Rating</RatingTilte>(
                    {data?.ratingdata?.review}):
                    <Rate
                      disabled
                      allowHalf
                      value={data?.ratingdata?.review}
                      style={{ color: "#F7931E" }}
                    />
                  </Rating>
                  <Rating>
                    <CommentWrapper>
                      <RatingTilte className="doc">
                        Review :
                        <ReviewWrapper>
                          {/* <RatingCommentWrapper> */}
                          {data?.ratingdata?.comment}
                          {/* </RatingCommentWrapper> */}
                        </ReviewWrapper>
                      </RatingTilte>
                    </CommentWrapper>
                  </Rating>
                </RateWrapper>
              </>
            )}
          </SignWrapper>
          <CompeteWrap>
            <FaCheckCircle size={50} />
            <Text>Completed Job</Text>
          </CompeteWrap>
        </UserWrapper>
      </Wrapper>
    </Container>
  );
};

export default OutCompletedJob;
