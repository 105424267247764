import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  TrackWrapper,
  TractLine,
  Line,
  Place,
  IconWrap,
  LocationText,
  LineWrap,
  Dot,
  StateLine,
  Location,
  TrackConainer,
  DataWrapper,
} from "styles/commonStyle";
import { BreadcrumbWrap, Container, TableTitle, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import { FaTruckFast } from "react-icons/fa6";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
  UpDateButton,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import truck2 from "assets/images/truck2.png";
import { NameText, NameWrap } from "styles/jobManagement";
import { DriverComponent } from "components/driverDetails";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { Breadcrumb } from "antd";
import { useDispatch } from "react-redux";
import { postApi } from "api/api.client";
import { APIS, jobDropDetails, locationDetails } from "api/api.constant";
import { UserDetail } from "constants/tableData";
import moment from "moment";
import { LocationTable } from "components/locationTable";
import { JobNote } from "components/jobNote";
import {
  calculateTotalPickupCount,
  getLocation,
  getdropLocation,
} from "components/commonFunction";

const UpcomingJobDetail = () => {
  const { userId, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});
  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.GET_JOB_DETAILS}`, { jobId: id, userId: userId })
      .then((res) => {
        let response: any = res;
        console.log("response57--", response);
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  const locationsData = data?.locations;
  const jobDropOffData = data?.jobDropOff;
  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Delivery Date", data: moment(data?.dates).format("ll") },
    { label: "Items", data: calculateTotalPickupCount(locationsData) },
    { label: "Amount", data: `£${data?.amount}` },
    {
      label: "Weight",
      data: data?.weight + " Kg" || 0,
    },
    {
      key: "time",
      label: "Time",
      data: data?.time_slot !== "ASAP" ? data?.start_time : data?.time_slot,
    },
  ];

  // 12 Jan. 2024

  // const locationDetails = [
  //   { text: "En Route", description: "" },
  //   { text: "Reached at location", description: "Arrived at Location" },
  //   { text: "Collected", description: "Collected 25 box" },
  // ];

  // console.log("data00", data);

  // const getLocation = (item: any) => {
  //   if (item.location) {
  //     return item.location;
  //   } else {
  //     return `${item?.addressLine1} ${item?.addressLine2} ${item?.city} ${item?.state}`;
  //   }
  // };

  return (
    <Container>
      <BreadcrumbWrap>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: <Link to={"/"}>Home</Link>,
            },
            {
              title: <Link to={"/user-management"}>User Management</Link>,
            },
            {
              title: (
                <Link to={`/user-management/${userId}`}>User Job Detail</Link>
              ),
            },
            {
              title: "Upcoming Job Details",
            },
          ]}
        />
      </BreadcrumbWrap>
      <Wrapper>
        <TopSection>
          <Title>Upcoming Job Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <UserWrapper>
          <NameWrap>
            <NameText>Driver Detail</NameText>
          </NameWrap>
          <DriverComponent data={data} />
          <VahicleWrapper>
            {vehicleData.map(({ label, data }) => (
              <TextWrapper>
                <Label>
                  <div>{label}</div>
                </Label>
                <DataWrapper>{data}</DataWrapper>
              </TextWrapper>
            ))}
          </VahicleWrapper>

          {data?.note?.length > 0 && <JobNote data={data?.note} />}
          {data?.document?.length > 0 && <Documents data={data?.document} />}
          {locationsData?.length > 0 && (
            <LocationTable
              locationsData={locationsData}
              jobDropOffData={jobDropOffData}
            />
          )}
          <TrackWrapper>
            <TableTitle className="doc">Location Detail</TableTitle>

            {locationsData?.map((item: any, index: any) => {
              return (
                <>
                  <TrackConainer key={index}>
                    <TractLine>
                      <Place>
                        {index === 0
                          ? "Job Started"
                          : getLocation(locationsData[index - 1])?.substring(
                              0,
                              16
                            )}
                      </Place>
                      <Line color="#606161" />
                      <IconWrap>
                        <img
                          src={truck2}
                          alt="truck_img"
                          width={50}
                          height={30}
                        />
                      </IconWrap>
                      <Place>{getLocation(item)?.substring(0, 16)}</Place>
                    </TractLine>
                    {locationDetails?.map((locationDetail, index) => (
                      <Location key={index}>
                        <LocationText color="#606161">
                          {locationDetail.text}
                        </LocationText>
                        <LineWrap>
                          <Dot color="#606161"></Dot>
                          <StateLine color="#606161"></StateLine>
                        </LineWrap>
                        <LocationText color="#606161" className="smallText">
                          {locationDetail.description === ""
                            ? getLocation(item)?.substring(0, 16)
                            : locationDetail.description}
                        </LocationText>
                      </Location>
                    ))}
                  </TrackConainer>
                </>
              );
            })}

            {jobDropOffData?.map((item: any, index: any) => {
              return (
                <>
                  <TrackConainer key={index}>
                    <TractLine>
                      <Place>
                        {index === 0
                          ? getLocation(
                              locationsData[locationsData?.length - 1]
                            )?.substring(0, 16)
                          : getdropLocation(
                              jobDropOffData[index - 1]
                            )?.substring(0, 16)}
                      </Place>
                      <Line color="#606161" />
                      <IconWrap>
                        <img
                          src={truck2}
                          alt="truck_img"
                          width={50}
                          height={30}
                        />
                      </IconWrap>
                      <Place>{getdropLocation(item)?.substring(0, 16)}</Place>
                    </TractLine>
                    {jobDropDetails?.map((locationDetail, index) => (
                      <Location key={index}>
                        <LocationText color="#606161">
                          {locationDetail.text}
                        </LocationText>
                        <LineWrap>
                          <Dot color="#606161"></Dot>
                          <StateLine color="#606161"></StateLine>
                        </LineWrap>
                        <LocationText color="#606161" className="smallText">
                          {locationDetail.description === ""
                            ? getdropLocation(item)?.substring(0, 16)
                            : locationDetail.description}
                        </LocationText>
                      </Location>
                    ))}
                  </TrackConainer>
                </>
              );
            })}
          </TrackWrapper>
        </UserWrapper>
      </Wrapper>
    </Container>
  );
};

export default UpcomingJobDetail;
