import { LuBuilding2, LuPhone, LuUser2 } from "react-icons/lu";
import { CiMail } from "react-icons/ci";
import * as Yup from "yup";

const Fields = {
  fullName: {
    title: "user",
    type: "text",
    name: "full_name",
    placeholder: "Full Name",
    className: "custome",
    component: "input",
    icon: <LuUser2 color="#A1A1A1" size={30} />,
    width: "400px",
  },
  userPhone: {
    title: "",
    type: "tel",
    name: "phone",
    placeholder: "Phone Number",
    className: "custome",
    component: "input",
    icon: <LuPhone color="#A1A1A1" size={30} />,
    width: "400px",
  },
  userEmail: {
    title: "",
    type: "email",
    name: "email",
    placeholder: "Email",
    className: "custome",
    component: "input",
    icon: <CiMail color="#A1A1A1" size={30} />,
    width: "400px",
    disabled: true,
  },
  companyPhoneNumber: {
    title: "",
    type: "tel",
    name: "company_number",
    placeholder: "Company Number",
    className: "custome",
    component: "input",
    icon: <LuPhone color="#A1A1A1" size={30} />,
    width: "400px",
  },
  company_name: {
    title: "",
    type: "tel",
    name: "company_name",
    placeholder: "Company Name",
    className: "custome",
    component: "input",
    icon: <LuBuilding2 color="#A1A1A1" size={30} />,
    width: "400px",
  },

  note: {
    rows: 4,
    placeholder: "Note..",
    minLength: 6,
    className: "textarea",
    component: "text-area",
  },
  dob: {
    name: "dob",
    className: "custome",
    componentClassName: "styleDate",
    placeholder: "Date Of Birth",
    component: "date-picker",
  },
  // gender: {
  //   name: "gender",
  //   className: "custome",
  //   placeholder: "Select Gender",
  //   component: "select",
  // },
};

const FieldsArray = {
  address1: {
    title: "",
    type: "text",
    name: "address1",
    placeholder: "Address Line 1",
    className: "custome",
    component: "input1",
    width: "400px",
  },
  address2: {
    title: "",
    type: "text",
    name: "address2",
    placeholder: "Address Line 2",
    className: "custome",
    component: "input1",
    width: "400px",
  },
  city: {
    title: "",
    type: "text",
    name: "city",
    placeholder: "City",
    className: "custome",
    component: "input1",
    width: "250px",
    // width: "400px",
  },
  state: {
    title: "",
    type: "text",
    name: "state",
    placeholder: "State",
    className: "custome",
    component: "input1",
    width: "250px",
    // width: "400px",
  },
  zipcode: {
    title: "",
    type: "text",
    name: "zipcode",
    placeholder: "Zip Code",
    className: "custome",
    component: "input1",
    width: "250px",
    // width: "400px",
  },

  location: {
    title: "",
    type: "text",
    name: "location",
    placeholder: "location",
    className: "custome",
    component: "input2",
    width: "400px",
  },
};
export const formSchema = {
  full_name: Yup.string()
    .min(3, "Username should be between (2-40) characters")
    .max(39, "Username should be between (2-40) characters")
    .matches(/^[a-zA-Z\s]+$/, "Name must contain only alphabets and spaces")
    .required("Required*"),

  email: Yup.string().email().required("Required*"),
  phone: Yup.string()
    .length(15, "Phone no. should be of 10 digits")
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .required("Required*"),
  company_number: Yup.string()
    .length(15, "Company no. should be of 10 digits")
    .matches(/^[0-9]+$/, "Company number must contain only digits")
    .required("Required*"),
  address1: Yup.string().max(50, "Address 1 should be max of 50 characters."),
  address2: Yup.string().max(50, "Address 2 should be max of 50 characters."),
  city: Yup.string()
    .min(4, "city should be between (3-40) characters")
    .max(39, "city should be between (3-40) characters"),
  state: Yup.string()
    .min(3, "state should be between (3-40) characters")
    .max(39, "state should be between (3-40) characters"),
  zipcode: Yup.string()
    .min(5, "Zipcode should be alphanumeric and between (4-8) characters")
    .max(8, "Zipcode should be alphanumeric and between (4-8) characters"),
  // .required("Required*"),

  location: Yup.string().max(60, "Address 2 should be max of 60 characters."),
  company_name: Yup.string().max(
    40,
    "Address 2 should be max of 40 characters."
  ),
};

export const UPDATE_USER_FORM: any = {
  form_fields: [
    [Fields.fullName, Fields.userPhone],
    [Fields.userEmail, Fields.companyPhoneNumber],

    [FieldsArray.address1, FieldsArray.address2],
    [FieldsArray.city, FieldsArray.state, FieldsArray.zipcode],
    [Fields.company_name, FieldsArray.location],
    [Fields.note],
  ],
  initialValues: {
    full_name: "",
    email: "",
    phone: "",
    password: "",
    company_number: "",
    company_name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    note: "",
    photo: "",
    location: "",
  },
  formSchema: Yup.object().shape({
    full_name: formSchema.full_name,
    email: formSchema.email,
    phone: formSchema.phone,
    company_number: formSchema.company_number,
    address1: formSchema.address1,
    address2: formSchema.address2,
    city: formSchema.city,
    state: formSchema.state,
    zipcode: formSchema.zipcode,
    location: formSchema.location,
    company_name: formSchema.company_name,
  }),
};

export const UPDATE_DRIVER_FORM: any = {
  form_fields: [
    [Fields.fullName, Fields.userPhone],
    [Fields.dob, Fields.userEmail],
    [FieldsArray.address1, FieldsArray.address2],
    [FieldsArray.city, FieldsArray.state, FieldsArray.zipcode],
    [FieldsArray.location],
    // [Fields.address2, Fields.city],
    // [Fields.state, Fields.zipcode],
    // [Fields.note],
  ],
  initialValues: {
    full_name: "",
    email: "",
    phone: "",
    password: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    note: "",
    photo: "",
    dob: "",
  },
  formSchema: Yup.object().shape({
    full_name: formSchema.full_name,
    email: formSchema.email,
    phone: formSchema.phone,
    address1: formSchema.address1,
    address2: formSchema.address2,
    city: formSchema.city,
    state: formSchema.state,
    zipcode: formSchema.zipcode,
  }),
};
