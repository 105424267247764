import {
  Action,
  BlockButton,
  Container,
  Title,
  UnBlockButton,
  UserImage,
  UserNameWrapper,
} from "styles/dashboard";
import {
  Amount,
  AmountWrap,
  Button,
  ComonWrapper,
  Download,
  DownloadText,
  Image,
  ImageWrapper,
  InnerContainer,
  InvoiceWrapper,
  Label,
  Late,
  PaymentWrapper,
  RadioButtons,
  SelectContainer,
  SelectWrap,
  SelectWrapper,
  Text,
  TotalAmount,
} from "styles/pages/financeMagement";
import {
  ActionsWrapper,
  Icon,
  TableWrapper,
  Wrapper,
} from "styles/pages/userManagement";
import image from "assets/images/financeImage.png";
import { Radio, RadioChangeEvent, Select } from "antd";
import { useEffect, useState } from "react";
import { InputWrapper } from "styles/pages/Login";
import { monthOptions } from "constants/monthsData";
import { IoIosCloudDownload } from "react-icons/io";
import TableContainer from "components/TableContainer";
import { DataType, TodayJobData, Userdata } from "constants/tableData";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import editIcone from "assets/images/edit.png";
import viewIcone from "assets/images/view.png";
import { Stops } from "styles/commonStyle";
import { postApi } from "api/api.client";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { APIS, BASE_URL } from "api/api.constant";
import moment from "moment";
import { getToast } from "utils";
import { ModalContent, FilterSearchButton } from "styles/pages/userManagement";
import { ModalTitle, ButtonWrapper, Reject, Accept } from "styles/modal";
import { Modal } from "antd";
import { ConfirmationRow } from "styles/updateProfile";
import { MdSettingsVoice } from "react-icons/md";
import { capitalizeFirstLetter } from "components/commonFunction";
import UserIcon from "../../assets/images/no-user.jpg";

interface InvoiceDetail {
  data: any;
}

const FinanceManagement = () => {
  const [value, setValue] = useState("driver");
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [datatable, setDataTable] = useState([]);
  const currentMonth = moment().format("MMMM");
  const [mark, setMark] = useState(false);
  const [invoice, setInvoice] = useState<InvoiceDetail["data"]>({});
  const [refresh, setRefresh] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(
    currentMonth ? currentMonth : null
  );

  useEffect(() => {
    const fetchDataForValue = async (value: string) => {
      if (!value) return;

      dispatch(toggleLoader({ loader: true }));
      try {
        postApi(APIS.DROP_DOWNLIST, {
          flagType: value,
        })
          .then((res) => {
            let response: any = res;
            setData(response?.data);
          })
          .catch((err) => {})
          .finally(() => {
            dispatch(toggleLoader({ loader: false }));
          });
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(toggleLoader({ loader: false }));
      }
    };

    fetchDataForValue(value);
  }, [value]);

  useEffect(() => {
    const fetchDataForValue = async (value: string) => {
      if (!value) return;
      if (!selected) return;
      dispatch(toggleLoader({ loader: true }));
      try {
        postApi(APIS.FINANCE_JOB_LIST, {
          id: selected,
          userRole: value,
          month: selectedMonth,
        })
          .then((res) => {
            let response: any = res;
            setDataTable(response?.data);
          })
          .catch((err) => {})
          .finally(() => {
            dispatch(toggleLoader({ loader: false }));
          });
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(toggleLoader({ loader: false }));
      }
    };

    const fetchLastInvoiceValue = async (value: string) => {
      if (!value) return;
      if (!selected) return;
      dispatch(toggleLoader({ loader: true }));
      try {
        postApi(APIS.GET_LAST_INVOICE, {
          userRole: value,
          userId: selected,
        })
          .then((res) => {
            let response: any = res;
            setInvoice(response?.data);
          })
          .catch((err) => {})
          .finally(() => {
            dispatch(toggleLoader({ loader: false }));
          });
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(toggleLoader({ loader: false }));
      }
    };

    fetchDataForValue(value);
    fetchLastInvoiceValue(value);
  }, [selected, refresh]);

  console.log("datatable", datatable);
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    setSelected("");
  };
  const handleChange = (value: any) => {
    setSelectedMonth(value);
  };

  const handleNavigation = (record: any) => {
    navigate(
      value == "driver"
        ? `/user-management/${record?.createdBy?._id}`
        : `/driver-management/${record?.driver?._id}`
    );
  };

  const handledownloadInvoice = () => {
    try {
      if (datatable?.length === 0) return;

      postApi(APIS.DOWNLOAD_INVOICE, {
        jobids: datatable.map((x: any) => x?._id),
        month: selectedMonth,
        userRole: value,
        userId: selected,
        amount: datatable
          ?.reduce((acc: any, job: any) => acc + parseFloat(job?.amount), 0)
          .toString(),
      })
        .then(async (res) => {
          let response: any = res;
          const fileUrl = response?.data?.url;
          const link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute("target", "_blank");
          link.setAttribute("rel", "noopener noreferrer");
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            document.body.removeChild(link);
          }, 100);
          getToast("success", "Generated successfully");
          setRefresh(!refresh);
        })
        .catch((err) => {})
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(toggleLoader({ loader: false }));
    }
  };

  // const handleDownload = async () => {
  //   try {
  //     // dispatch(toggleLoader({ loader: true }));
  //     // const fileUrl = invoice.url;
  //     const fileUrl = `${invoice?.url}`;
  //     // const link = document.createElement("a");
  //     // link.href = fileUrl;
  //     // link.setAttribute("download", invoice?.invoiceId);
  //     // document.body.appendChild(link);
  //     // link.click();
  //     // document.body.removeChild(link);
  //     // console.log("document", document);
  //     const link = document.createElement("a");

  //     link.href = fileUrl;
  //     link.download = "document.pdf"; // Specify the filename
  //     link.style.display = "none"; // Make sure the link is not visible

  //     document.body.appendChild(link);
  //     link.click(); // Trigger the download
  //     document.body.removeChild(link);
  //     // const a = document.createElement("a");
  //     // a.href = fileUrl;
  //     // a.download = "document";
  //     // a.click();

  //     getToast("success", "Downloaded Successfully");
  //   } catch (error) {
  //     console.error("API Error:", error);
  //   }
  // };

  const onConfirmClick = () => {
    try {
      postApi(APIS.UPDATE_INVOICE, {
        jobid: datatable.map((x: any) => x._id),
      })
        .then((res) => {
          getToast("success", "Mark As Paid Successfully");
          setMark(false);
          setRefresh(!refresh);
        })
        .catch((err) => {})
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(toggleLoader({ loader: false }));
    }
  };

  // Usage
  const userColumns: ColumnsType<DataType> = [
    {
      title: `${value == "driver" ? "Customer" : "Driver"}  Name`,
      key: "name",
      render: (_, record) => (
        <UserNameWrapper
          onClick={
            () => handleNavigation(record)
            // navigate(`/user-management/1 )
          }
        >
          <UserImage
            src={
              value == "driver"
                ? record?.createdBy?.photo !== null
                  ? record?.createdBy?.photo
                  : UserIcon
                : record?.driver?.photo !== null
                ? record?.driver?.photo
                : UserIcon
            }
          />
          {value === "driver"
            ? record?.createdBy?.full_name &&
              record?.createdBy?.full_name !== "Nan"
              ? capitalizeFirstLetter(record.createdBy.full_name)
              : ""
            : record?.driver?.full_name && record?.driver?.full_name !== "Nam"
            ? capitalizeFirstLetter(record.driver.full_name)
            : ""}
        </UserNameWrapper>
      ),
    },
    {
      title: "First Location",
      dataIndex: "FirstLocation",
      key: "First Location",
      render: (_, record) => (
        <div>
          {record?.locations?.location ||
            record?.locations?.addressLine1 +
              " " +
              record?.locations?.addressLine2 +
              " " +
              record?.locations?.city +
              " " +
              record?.locations?.state}
        </div>
      ),
    },

    {
      title: <Stops>Vehicle</Stops>,
      dataIndex: "vehicle",
      render: (_, record) => {
        return <Stops>{capitalizeFirstLetter(record?.vehicle)}</Stops>;
      },
    },
    {
      title: "Type",
      dataIndex: "staticKey",
      key: "staticKey",
      render: (_, record) => <div>{record?.staticKey}</div>,
    },
    {
      title: "Date",
      dataIndex: "dates",
      key: "dates",
      render: (_, record) => <div> {moment(record?.dates).format("ll")}</div>,
    },

    {
      title: <Stops>Total Distance</Stops>,
      dataIndex: "totalDistance",
      render: (_, record) => {
        return <Stops>{record?.totalDistance} Miles</Stops>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "price",
      render: (_, record) => {
        return <div>{record?.amount}</div>;
      },
    },
  ];

  const driverColumns: ColumnsType<DataType> = [...userColumns];
  driverColumns.splice(2, 0, {
    title: <Stops>Paid</Stops>,
    dataIndex: "isPaid",
    render: (_, record) => {
      return <Stops>{record?.isPaid === true ? "True" : "False"}</Stops>;
    },
  });

  return (
    <Container>
      <Wrapper>
        <Title>Finance Management List</Title>
        <InnerContainer>
          <SelectContainer>
            {!selected && (
              <ImageWrapper>
                <Image src={image} />
              </ImageWrapper>
            )}

            <ComonWrapper>
              <SelectWrapper>
                <RadioButtons>
                  {!selected && <Label>Select User</Label>}
                  <Radio.Group
                    onChange={onChange}
                    value={value}
                    className="radio"
                  >
                    <Radio value={"driver"} className="radio-text">
                      Driver
                    </Radio>
                    <Radio value={"user"} className="radio-text">
                      Customer
                    </Radio>
                  </Radio.Group>
                </RadioButtons>
                <SelectWrap>
                  <InputWrapper className={"select"}>
                    <Select
                      onChange={(e) => setSelected(e)}
                      placeholder={`Select ${value}`}
                      options={data?.map((item: any) => ({
                        value: item._id,
                        label: capitalizeFirstLetter(item?.full_name),
                      }))}
                      className={"custome-select"}
                      value={selected ? selected : `Select ${value}`}
                    />
                    {/* <Select.Option>
                        <input />
                      </Select.Option>
                      {data?.map((item: any) => {
                        return (
                          <Select.Option key={item._id} value={item._id}>
                            {capitalizeFirstLetter(item?.full_name)}
                          </Select.Option>
                        );
                      })}
                    </Select> */}
                  </InputWrapper>
                  <InputWrapper className={"select"}>
                    <Select
                      className={"custome-select"}
                      placeholder="Select Months"
                      options={monthOptions}
                      onChange={handleChange}
                      value={selectedMonth}
                    />
                  </InputWrapper>
                </SelectWrap>
              </SelectWrapper>
              {selected && Object.keys(invoice).length > 0 && (
                <PaymentWrapper>
                  {value == "driver" ? (
                    <Late>
                      Last Payment Generated :{" "}
                      {moment(invoice?.date).format("DD-MMM-YYYY")}
                    </Late>
                  ) : (
                    <Late>
                      Last Payment Raised :{" "}
                      {moment(invoice?.date).format("DD-MMM-YYYY")}
                    </Late>
                  )}
                  <AmountWrap>
                    <Amount>
                      {Number(invoice.amount)?.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })}{" "}
                    </Amount>
                    <Text>Total Amount</Text>

                    <Download
                      style={{ cursor: "pointer" }}
                      // onClick={(e) => {
                      //   handleDownload();
                      //   // console.log(`${BASE_URL}${invoice?.url}`);
                      // }}
                      target="_blank"
                      href={invoice?.url}
                      download={invoice?.invoiceId}
                      // onClick={() => window.open(invoice.url, "_blank")}
                    >
                      <IoIosCloudDownload size={16} />
                      <DownloadText>Previous Invoice</DownloadText>
                    </Download>
                  </AmountWrap>
                </PaymentWrapper>
              )}
            </ComonWrapper>
          </SelectContainer>
          {selected && (
            <>
              <TableWrapper className="customeTable">
                <Table
                  className="tableStyle"
                  columns={value == "user" ? userColumns : driverColumns}
                  // dataSource={TodayJobData}
                  dataSource={datatable}
                  pagination={false}
                />
              </TableWrapper>

              <InvoiceWrapper>
                {value == "driver" ? (
                  <Button onClick={() => setMark(true)}>Mark As Paid</Button>
                ) : (
                  <></>
                )}
                <Button onClick={() => handledownloadInvoice()}>
                  Generate Invoice
                </Button>
                <TotalAmount>
                  Total Amount:{" "}
                  <span>
                    {datatable
                      .reduce(
                        (acc: any, job: any) => acc + parseFloat(job?.amount),
                        0
                      )
                      ?.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })}
                  </span>
                </TotalAmount>
              </InvoiceWrapper>
            </>
          )}
        </InnerContainer>
      </Wrapper>

      {/* =============== Modal ======= */}

      <Modal
        open={mark}
        onOk={onConfirmClick}
        onCancel={() => setMark(false)}
        footer={false}
        centered
      >
        <ModalContent>
          <ModalTitle>Are you sure you want mark as Paid ?</ModalTitle>
          <div style={{ fontSize: "16px", marginTop: "6px" }}></div>
          <ConfirmationRow>
            <ButtonWrapper
              onClick={() => setMark(false)}
              style={{ paddingTop: "14px" }}
            >
              <Reject style={{ height: "30px", width: "100px" }}>No</Reject>
            </ButtonWrapper>
            <ButtonWrapper
              onClick={onConfirmClick}
              style={{ paddingTop: "14px" }}
            >
              <Accept style={{ height: "30px", width: "100px" }}>yes</Accept>
            </ButtonWrapper>
          </ConfirmationRow>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default FinanceManagement;
