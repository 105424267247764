import {
  UserWrapper,
  TabWrapper,
  ButtonWrapper as ButtonWrap,
  TabButton,
} from "styles/commonStyle";
import {
  Action,
  BlockButton,
  BreadcrumbWrap,
  Container,
  Title,
  UnBlockButton,
  UserImage,
  UserNameWrapper,
} from "styles/dashboard";
import { ActionsWrapper, Icon, Wrapper } from "styles/pages/userManagement";
import { Breadcrumb, TabsProps } from "antd";
import TableContainer from "components/TableContainer";
import { ColumnsType } from "antd/es/table";
import { DataType, TodayJobData } from "constants/tableData";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";
import viewIcone from "assets/images/view.png";
import seprator from "assets/images/seprator.png";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
  UpDateButton,
} from "styles/unAssignedJob";
import { FaRegEdit } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { DriverComponent } from "components/driverDetails";
import { useDispatch } from "react-redux";
import { postApi } from "api/api.client";
import { APIS, APP_BASE_URL } from "api/api.constant";
import { FilterValue } from "interfaces/iCommon";
import UserIcon from "../../../../assets/images/no-user.jpg";
import {
  DATE_FILTERS,
  PAGINATION_SIZE,
  filterInitialValue,
} from "constants/common";
import { CommonFilter } from "components";
import {
  disabledFutureDate,
  disabledPastAndTodayJob,
} from "components/dateInput";
import {
  calculateTotalPickupCount,
  capitalizeFirstLetter,
  shortenLocation,
} from "components/commonFunction";
import moment from "moment";
import { DriverDocuments } from "components/driverdocument";

interface DriverDetail {
  data: any;
}

const DriverJobDetails = () => {
  const tabdata = ["Today's Jobs", "Upcoming Jobs", "Past Jobs"];
  const tabdata2 = ["Today's Jobs", "Upcoming Jobs", "Past Jobs", "Unassigned"];
  const filterTab = ["In Jobs", "Out Jobs"];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const currentInTab = queryParams.get("intab");
  const currentInSetTab = queryParams.get("insettab");
  const currentOutTab = queryParams.get("outtab");
  const currentOutSetTab = queryParams.get("outsettab");

  const dispatch = useDispatch();
  const [data, setData] = useState<DriverDetail["data"]>({});
  const [filter, setfilter] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Number(currentInTab) || Number(currentOutTab) || 0
  );
  const [activeFilterTab, setActiveFilterTab] = useState(
    Number(currentInSetTab) || Number(currentOutSetTab) || 0
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGINATION_SIZE);
  const [filterValue, setFilterValue] =
    useState<FilterValue>(filterInitialValue);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.GET_DRIVER_BY_ID}/${id}`, {})
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  // const shortenLocation = (location: string) => {
  //   const maxLength = 16;
  //   return location?.length > maxLength
  //     ? `${location?.substring(0, maxLength)}...`
  //     : location;
  // };

  useEffect(() => {
    if (activeFilterTab === 0 && activeTab === 3) {
      setActiveTab(0);
    }
  }, [activeFilterTab, activeTab]);

  useEffect(() => {
    const handleSelect = () => {
      if (activeFilterTab === 0) {
        queryParams.set("intab", String(activeTab));
        queryParams.set("insettab", String(activeFilterTab));
        queryParams.delete("outtab");
        queryParams.delete("outsettab");
      } else {
        queryParams.set("outtab", String(activeTab));
        queryParams.set("outsettab", String(activeFilterTab));
        queryParams.delete("intab");
        queryParams.delete("insettab");
      }
      const newUrl = `${location.pathname}?${queryParams}`;
      window.history.replaceState(null, "", newUrl);
    };

    handleSelect();
  }, [activeTab, activeFilterTab]);

  const getPostApiRoute = (activeTab: number, activeFilterTab: number): any => {
    switch (activeFilterTab * 10 + activeTab) {
      case 0:
        return APIS.DRIVER_TODAYINJOB;
      case 1:
        return APIS.DRIVER_UPCOMINGINJOB;
      case 2:
        return APIS.DRIVER_PASTINJOB;
      case 10:
        return APIS.DRIVER_TODAYOUTJOB;
      case 11:
        return APIS.DRIVER_UPCOMINGOUTJOB;
      case 12:
        return APIS.DRIVER_PASTOUTJOB;
      case 13:
        return APIS.DRIVER_UNASSIGNOUTJOB;
      default:
        return APIS.DRIVER_UNASSIGNOUTJOB;
    }
  };

  const fetchJobData = (route: string) => {
    dispatch(toggleLoader({ loader: true }));
    postApi(route, {
      ...filterValue,
      page: page,
      size: pageSize,
      userId: id,
    })
      .then((res) => {
        let response: any = res;
        setTableData(response?.data?.jobInfo);
        setTotalCount(response?.data?.pagination?.totalCount);
      })
      .catch((err) => {})
      .finally(() => {
        // setFilterValue(filterInitialValue);
        dispatch(toggleLoader({ loader: false }));
      });
  };

  useEffect(() => {
    fetchJobData(getPostApiRoute(activeTab, activeFilterTab));
  }, [triggerFilter, page, pageSize, activeTab, activeFilterTab]);

  const columns: ColumnsType<DataType> = [
    {
      title: activeFilterTab !== 1 ? "User Name" : "Driver Name",
      key: "name",
      render: (_, record: any) => (
        <UserNameWrapper>
          <UserImage
            src={
              activeFilterTab !== 1 && record?.createdBy?.photo !== null
                ? record?.createdBy?.photo
                : record?.driverId?.photo !== null
                ? record?.driverId?.photo
                : UserIcon
            }
          />
          {activeFilterTab !== 1
            ? capitalizeFirstLetter(record?.createdBy?.full_name)
            : capitalizeFirstLetter(record?.driverId?.full_name)}
        </UserNameWrapper>
      ),
    },
    {
      title: "First Location",
      dataIndex: "FirstLocation",
      key: "First Location",
      render: (_, record: any) => {
        const firstLocation =
          record?.locations[0]?.location ||
          record?.locations[0]?.addressLine1 +
            " " +
            record?.locations[0]?.addressLine2;
        return <div>{firstLocation}</div>;
      },
    },
    {
      title: "Last Location",
      dataIndex: "LastLocation",
      key: "last Location",
      render: (_, record: any) => {
        const lastLocation =
          record?.jobDropOff[record?.jobDropOff?.length - 1]
            ?.drop_off_location ||
          record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine1 +
            " " +
            record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine2;
        return <div>{lastLocation}</div>;
      },
    },
    {
      title: <Action>Total Stops</Action>,
      dataIndex: "totalStops",
      key: "Total Stops",
      render: (_, record) => {
        return <Action>{record?.totalStops}</Action>;
      },
    },
    {
      title: "Total Items",
      dataIndex: "totalItems",
      render: (_, record) => {
        return <div>{calculateTotalPickupCount(record?.locations)}</div>;
      },
    },

    {
      title: <Action>Action</Action>,
      key: "Action",
      render: (_, record) => {
        return (
          <ActionsWrapper
            onClick={() =>
              activeFilterTab === 1
                ? navigate(
                    `/driver-management/${id}/todayJob/out/${record?._id}`,
                    { state: { data: record } }
                  )
                : navigate(`/driver-management/${id}/todayJob/${record?._id}`, {
                    state: { data: record },
                  })
            }
          >
            <Icon src={viewIcone} />
          </ActionsWrapper>
        );
      },
    },
  ];

  const PostJob: ColumnsType<DataType> = [...columns];
  PostJob.splice(0, 1);
  PostJob.splice(3, 1, {
    title: "Amount",
    dataIndex: "amount",
    render: (_, record) => {
      return (
        <div>
          {Number(record?.amount || 0)?.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          })}
        </div>
      );
    },
  });

  PostJob.splice(4, 1, {
    title: <Action>Actions</Action>,
    key: "Action",
    render: (_, record) => {
      return (
        <ActionsWrapper
          onClick={() =>
            record?.status === "Cancelled"
              ? activeFilterTab === 1
                ? navigate(
                    `/driver-management/${id}/cancelled/out/${record?._id}`,
                    { state: { data: record } }
                  )
                : navigate(
                    `/driver-management/list/${id}/cancelled/${record?._id}`,
                    { state: { data: record } }
                  )
              : activeFilterTab === 1
              ? navigate(
                  `/driver-management/${id}/completed/out/${record?._id}`,
                  { state: { data: record } }
                )
              : navigate(`/driver-management/${id}/completed/${record?._id}`, {
                  state: { data: record },
                })
          }
        >
          <Icon src={viewIcone} />
        </ActionsWrapper>
      );
    },
  });

  PostJob.splice(-1, 0, {
    title: <Action>Status</Action>,
    key: "status",
    render: (_, record) => {
      return (
        <Action>
          {record?.status === "Cancelled" ? (
            <BlockButton
              style={{ backgroundColor: "#FFD5D2", color: "#F34235" }}
            >
              Cancelled
            </BlockButton>
          ) : (
            <UnBlockButton
              style={{ backgroundColor: "#C2FFCF", color: "#01851E" }}
            >
              Completed
            </UnBlockButton>
          )}
        </Action>
      );
    },
  });

  const UpcomingJobcolumns: ColumnsType<DataType> = [...columns];
  UpcomingJobcolumns.splice(0, 1);

  UpcomingJobcolumns.splice(4, 1, {
    title: <Action>Actions</Action>,
    key: "Action",
    render: (_, record) => {
      return (
        <ActionsWrapper
          onClick={() =>
            activeFilterTab === 1
              ? navigate(
                  `/driver-management/${id}/upcoming/out/${record?._id}`,
                  {
                    state: { data: record },
                  }
                )
              : navigate(`/driver-management/${id}/upcoming/${record?._id}`, {
                  state: { data: record },
                })
          }
        >
          <Icon src={viewIcone} />
        </ActionsWrapper>
      );
    },
  });
  UpcomingJobcolumns.splice(2, 0, {
    // title: "Time",
    // dataIndex: "time",
    // key: "time",
    // render: (_, record) => {
    //   return (
    //     <>
    //       {record?.time_slot !== "ASAP" ? record.end_time : record?.time_slot}{" "}
    //     </>
    //   );
    // },

    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (_, record: any) => (
      <div>{moment(record?.dates, "YYYY-MM-DD").format("ll")}</div>
    ),
  });

  const UnAssignedJob: ColumnsType<DataType> = [...columns];
  UnAssignedJob.splice(0, 1);
  UnAssignedJob.splice(4, 1, {
    title: <Action>Actions</Action>,
    key: "Action",
    render: (_, record) => {
      return (
        <ActionsWrapper
          onClick={() =>
            navigate(`/driver-management/${id}/unassignedJob/${record?._id}`, {
              state: { data: data, record: record },
            })
          }
        >
          <Icon src={viewIcone} />
        </ActionsWrapper>
      );
    },
  });
  UnAssignedJob.splice(-1, 0, {
    title: "Vehicle Type",
    dataIndex: "vehicle",
    key: "Vehicle",
  });

  const onResetClick = () => {
    setFilterValue(filterInitialValue);
    setTriggerFilter(!triggerFilter);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <div>Todays's Job</div>,
      children: (
        <TableContainer
          title={"Today's Jobs"}
          data={tableData}
          colums={columns}
          activeFilterTab={activeFilterTab}
          activeTab={activeTab}
          onResetClick={onResetClick}
          filterValue={filterValue}
          setfilter={setfilter}
          filter={filter}
          type="user"
          filtertext={"Filter"}
          totalCount={totalCount}
          onPaginationChange={(page: any, pageSize: any) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      ),
    },
    {
      key: "2",
      label: <div>Upcoming Jobs </div>,
      children: (
        <TableContainer
          title={"Upcoming Jobs"}
          data={tableData}
          colums={UpcomingJobcolumns}
          onResetClick={onResetClick}
          setfilter={setfilter}
          filterValue={filterValue}
          filter={filter}
          type="user"
          filtertext={"Filter"}
          totalCount={totalCount}
          onPaginationChange={(page: any, pageSize: any) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      ),
    },
    {
      key: "3",
      label: <div>Past Jobs </div>,
      children: (
        <TableContainer
          amount={true}
          title={"Past Jobs"}
          data={tableData}
          colums={PostJob}
          onResetClick={onResetClick}
          setfilter={setfilter}
          filterValue={filterValue}
          filter={filter}
          type="user"
          filtertext={"Filter"}
          totalCount={totalCount}
          onPaginationChange={(page: any, pageSize: any) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      ),
    },
    {
      key: "4",
      label: <div>Un Assigned Jobs</div>,
      children: (
        <TableContainer
          title={"Post Jobs"}
          data={tableData}
          colums={UnAssignedJob}
          onResetClick={onResetClick}
          filterValue={filterValue}
          setfilter={setfilter}
          filter={filter}
          type="user"
          filtertext={"Filter"}
          totalCount={totalCount}
          onPaginationChange={(page: any, pageSize: any) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      ),
    },
  ];

  const handleOk = () => {
    setfilter(false);
  };

  const handleCancel = () => {
    setfilter(false);
  };
  const onSearchClick = () => {
    if (filterValue.date_from !== null && filterValue.date_to !== null) {
      setTriggerFilter(!triggerFilter);
      setfilter(!filter);
    }
  };

  let disabledDateFunction;
  let job_type;
  switch (activeFilterTab * 10 + activeTab) {
    case 1: // Upcoming in jobs
      disabledDateFunction = disabledPastAndTodayJob;
      job_type = "upcomming_job";
      break;
    case 2: // Past in jobs
      disabledDateFunction = disabledFutureDate;
      job_type = "past_job";
      break;
    case 11: // Upcoming out jobs
      disabledDateFunction = disabledPastAndTodayJob;
      job_type = "upcomming_job";
      break;
    case 12: // Past out jobs
      disabledDateFunction = disabledFutureDate;
      job_type = "past_job";
      break;
    case 13: // Unassigned out jobs
      disabledDateFunction = false;
      break;
    default:
      disabledDateFunction = false;
  }

  return (
    <Container>
      <BreadcrumbWrap>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: <Link to={"/"}>Home</Link>,
            },
            {
              title: (
                <Link to={"/driver-management/List"}>
                  Driver Management List
                </Link>
              ),
            },
            {
              title: "Driver Details",
            },
          ]}
        />
      </BreadcrumbWrap>
      <Wrapper>
        <TopSection>
          <Title>Driver Details</Title>
          <ButtonWrapper>
            <UpDateButton
              onClick={() => navigate(`/driver-management/update/${id}`)}
            >
              <FaRegEdit size={15} />
              <ButtonText> Edit/Update</ButtonText>
            </UpDateButton>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={18} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <UserWrapper>
          <DriverComponent data={data} />
        </UserWrapper>
        <TabWrapper>
          {activeFilterTab !== 1 ? (
            <ButtonWrap>
              {tabdata?.map((item, index) => {
                return (
                  <TabButton
                    isActive={activeTab === index}
                    onClick={() => setActiveTab(index)}
                  >
                    {item}
                  </TabButton>
                );
              })}
            </ButtonWrap>
          ) : (
            <ButtonWrap>
              {tabdata2?.map((item, index) => {
                return (
                  <TabButton
                    isActive={activeTab === index}
                    onClick={() => setActiveTab(index)}
                    // onClick={() => handleActiveTabClick(index)}
                  >
                    {item}
                  </TabButton>
                );
              })}
            </ButtonWrap>
          )}

          <ButtonWrap>
            {filterTab?.map((item, index) => {
              return (
                <TabButton
                  isActive={activeFilterTab === index}
                  onClick={() => setActiveFilterTab(index)}
                >
                  {item}
                </TabButton>
              );
            })}
          </ButtonWrap>
        </TabWrapper>

        {items?.map((item, index) => {
          if (index === activeTab) {
            return item.children;
          }
        })}

        {filter && (
          <CommonFilter
            filter={filter}
            handleOk={handleOk}
            handleCancel={handleCancel}
            DATE_FILTERS={DATE_FILTERS}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            onSearchClick={onSearchClick}
            disabledDate={disabledDateFunction}
            job_type={job_type}
            isDisabled={filterValue.date_from === "" ? true : false}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default DriverJobDetails;
