import { postApi } from "api/api.client";
import { APIS } from "api/api.constant";
import Navbar from "components/navbar";
import { CSM_SLUG_TYPE } from "constants/common";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Wrapper, Title, Title2, Content } from "styles/view";

export const MobileAdditionalClauses = () => {
  const [data, setData] = useState("");
  // useEffect(() => {
  //   postApi(APIS.GET_CMS, {
  //     type: CSM_SLUG_TYPE.TERMS_AND_CONDITIONS,
  //   })
  //     .then((res) => {
  //       let response: any = res;
  //       setData(response?.data?.content);
  //     })
  //     .catch((err) => {})
  //     .finally(() => {});
  // }, []);

  return (
    <Container>
      <Wrapper>
        {/* <Title>Terms & Conditions</Title>
        <Content dangerouslySetInnerHTML={{ __html: data }}></Content> */}

        <h1>Final Agreement</h1>
        <h2>1. Introduction</h2>
        <p>
          This Final Agreement ("Agreement") is entered into between Kindra
          Cargo Ltd ("Company", "we", "our", or "us") and the user ("User",
          "you", or "your") of the Kindra Cargo courier app ("App"). This
          Agreement incorporates by reference the Privacy and Data Protection
          Policy and the Service Agreement, both of which you have already
          agreed to.
        </p>

        <h2>2. Definitions</h2>
        <ul>
          <li>
            <strong>"App"</strong>: The Kindra Cargo courier application for
            drivers to accept bulk loads from customers.
          </li>
          <li>
            <strong>"Confidential Information"</strong>: Any non-public
            information related to the Company's business, technology, or
            operations, including but not limited to user data, pricing
            strategies, and proprietary algorithms.
          </li>
          <li>
            <strong>"Dispute"</strong>: Any controversy or claim arising out of
            or relating to this Agreement, the Privacy Policy, or the Service
            Agreement.
          </li>
        </ul>

        <h2>3. Acceptance of Terms</h2>
        <p>
          By signing this Agreement, you acknowledge that you have read,
          understood, and agree to be bound by the terms of this Agreement, as
          well as the Privacy and Data Protection Policy and the Service
          Agreement.
        </p>

        <h2>4. Confidentiality Agreement</h2>
        <h3>4.1 Confidential Information:</h3>
        <p>
          You agree to keep confidential any non-public information you may
          access or become aware of through your use of the App. This includes,
          but is not limited to:
        </p>
        <ul>
          <li>User data and personal information</li>
          <li>Pricing strategies and algorithms</li>
          <li>Technical specifications of the App</li>
          <li>Business plans and strategies of the Company</li>
        </ul>

        <h3>4.2 Obligations:</h3>
        <ul>
          <li>
            Use Confidential Information solely for the purpose of using the App
            as intended
          </li>
          <li>
            Not disclose Confidential Information to any third party without
            prior written consent from the Company
          </li>
          <li>
            Take reasonable measures to protect the secrecy of and avoid
            disclosure or use of Confidential Information
          </li>
        </ul>

        <h3>4.3 Exclusions:</h3>
        <p>
          This confidentiality obligation does not apply to information that:
        </p>
        <ul>
          <li>Is or becomes publicly known through no fault of your own</li>
          <li>
            Is rightfully received from a third party without a duty of
            confidentiality
          </li>
          <li>
            Is independently developed by you without use of Confidential
            Information
          </li>
          <li>Is required to be disclosed by law or government authority</li>
        </ul>

        <h3>4.4 Duration:</h3>
        <p>
          Your confidentiality obligations under this Agreement shall continue
          for a period of two (2) years after the termination of your use of the
          App.
        </p>

        <h2>5. Dispute Resolution</h2>
        <h3>5.1 Informal Resolution:</h3>
        <p>
          In the event of any dispute arising out of or relating to this
          Agreement, the parties agree to first attempt to resolve the dispute
          informally by contacting each other through the App or using the
          contact information provided in the Privacy Policy.
        </p>

        <h3>5.2 Mediation:</h3>
        <p>
          If the parties are unable to resolve the dispute informally within 30
          days, they agree to submit the dispute to mediation. The mediation
          shall be conducted by a mutually agreed-upon mediator in England.
        </p>

        <h3>5.3 Arbitration:</h3>
        <p>
          If mediation is unsuccessful, any unresolved dispute shall be settled
          by binding arbitration in accordance with the rules of the London
          Court of International Arbitration. The arbitration shall take place
          in London, England, and shall be conducted in the English language.
        </p>

        <h3>5.4 Class Action Waiver:</h3>
        <p>
          You agree to resolve any disputes on an individual basis and waive any
          right to pursue any claims on a class or consolidated basis or in a
          representative capacity.
        </p>

        <h3>5.5 Governing Law:</h3>
        <p>
          This Agreement and any disputes arising out of or related to it shall
          be governed by and construed in accordance with the laws of England
          and Wales.
        </p>

        <h2>6. Relationship to Other Agreements</h2>
        <h3>
          6.1 This Agreement is supplemental to and incorporates by reference
          the following documents:
        </h3>

        <ul>
          <li>Privacy and Data Protection Policy (last updated 30/07/2024)</li>
          <li>Service Agreement for Kindra Cargo Ltd Courier App</li>
        </ul>

        <h3>6.2 In the event of any conflict between this Agreement</h3>
        <p>
          and the documents listed in Section 6.1, the terms of this Agreement
          shall prevail.
        </p>

        <h2>7. Amendments and Updates</h2>
        <p>
          We reserve the right to update this Agreement from time to time. We
          will notify you of any significant changes via email and in-app
          notification at least 30 days before the changes take effect. Your
          continued use of the App after such changes constitutes acceptance of
          the updated Agreement.
        </p>

        <h2>8. Termination</h2>
        <h3 className="">8.1 You may terminate this Agreement</h3>
        <p>at any time by ceasing to use the App and deleting your account.</p>

        <h3>8.2 We may terminate this Agreement</h3>
        <p>
          and your access to the App if you breach any term of this Agreement,
          the Privacy Policy, or the Service Agreement.
        </p>

        <h3>8.3 Upon termination,</h3>
        <p>
          the Confidentiality Agreement (Section 4) shall survive for the
          duration specified therein.
        </p>

        <h2>9. Miscellaneous</h2>
        <h3>9.1 Severability:</h3>
        <p>
          If any provision of this Agreement is found to be unenforceable or
          invalid, that provision shall be limited or eliminated to the minimum
          extent necessary so that this Agreement shall otherwise remain in full
          force and effect and enforceable.
        </p>

        <h3>9.2 Entire Agreement:</h3>
        <p>
          This Agreement, together with the Privacy and Data Protection Policy
          and the Service Agreement, constitutes the entire agreement between
          you and the Company regarding the use of the App.
        </p>

        <h3>9.3 Contact Information:</h3>
        <p>For any questions about this Agreement, please contact us at:</p>
        <p>
          Kindra Cargo LTD
          <br />
          181 Balfour Street, OL41NS
          <br />
          Email: admin@kindracargo.co.uk
          <br />
          Phone: +44 7441395840
        </p>

        <h2>10. Signature</h2>
        <p>
          By signing below, you acknowledge that you have read, understood, and
          agree to be bound by the terms of this Final Agreement, including the
          incorporated Privacy and Data Protection Policy and Service Agreement.
        </p>
      </Wrapper>
    </Container>
  );
};
