import Navbar from "components/navbar";
import { useLocation } from "react-router-dom";
import { Editor } from "styles/pages/setting";
import { Container, Wrapper, Title, Title2, Content } from "styles/view";

export const AboutUsView = () => {
  const location = useLocation();
  const { payload } = location.state || {};
  return (
    <>
      <Container>
        {/* <Navbar classname={"customeNav"} /> */}
        <Wrapper>
          <Title>Transport Your Goods Around the World. </Title>
          {/* <Title2>Introduction</Title2> */}
          <Content dangerouslySetInnerHTML={{ __html: payload }}>
            {/* These Website Standard Terms and Conditions written on this webpage
            shall manage your use of our website, Webiste Name accessible at
            Website.com. These Terms will be applied fully and affect to your
            use of this Website. By using this Website, you agreed to accept all
            terms and conditions written in here. You must not use this Website
            if you disagree with any of these Website Standard Terms and
            Conditions.
            <br /> Minors or people below 18 years old are not allowed to use
            this Website <br /> Other than the content you own, under these
            Terms, Company Name and/or its licensors own all the intellectual
            property rights and materials contained in this Website.
            <br />
          </Content>
          <Content>
            You are granted limited license only for purposes of viewing the
            material contained on this Website. Intellectual Property Rights
          </Content>

          <Title>Restriction</Title>
          <Title2>
            You are specifically restricted from all of the following:
          </Title2>
          <Content>
            <ul>
              <li>publishing any Website material in any other media</li>
              <li>
                selling, sublicensing and/or otherwise commercializing any
                Website material
              </li>
              <li>publicly performing and/or showing any Website material</li>
              <li>
                using this Website to engage in any advertising or marketing
              </li>
              <li>
                using this Website contrary to applicable laws and regulations,
                or in any way may cause harm to the Website, or to any person or
                business entity;
              </li>
              <li>
                using this Website to engage in any advertising or marketing.
              </li>
            </ul>
            Certain areas of this Website are restricted from being access by
            you and Company Name may further restrict access by you to any areas
            of this Website, at any time, in absolute discretion. Any user ID
            and password you may have for this Website are confidential and you
            must maintain confidentiality as well.
          </Content>
          <Content>
            You are granted limited license only for purposes of viewing the
            material contained on this Website. Intellectual Property Rights
          </Content>

          <Title>Content</Title>
          <Content>
            In these Website Standard Terms and Conditions, “Your Content” shall
            mean any audio, video text, images or other material you choose to
            display on this Website. By displaying Your Content, you grant
            Company Name a non-exclusive, worldwide irrevocable, sub licensable
            license to use, reproduce, adapt, publish, translate and distribute
            it in any and all media. // Your Content must be your own and must
            not be invading any third-party's rights. Company Name reserves the
            right to remove any of Your Content from this Website at any time
            without notice. // No warranties */}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};
